import {useViewer} from '../hooks/auth';

export type permissionsList = {
  haveSettings?: boolean;
  isSuperAdmin?: boolean;
};

export const getPermissions = (): permissionsList => {
  const user = useViewer();
  return {
    haveSettings: !!user?.roles?.find((el) => el === 'SUPER ADMIN'),
    isSuperAdmin: !!user?.roles?.find((el) => el === 'SUPER ADMIN'),
  };
};
