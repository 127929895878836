import {TimePicker} from 'antd';
import styled from 'styled-components';

export const StyledTimePicker = styled(TimePicker)`
  &.ant-picker {
    width: 100%;
    height: 38px;
    border-radius: 5px;
  }
  &.ant-picker:hover,
  &.ant-picker-focused {
    border-color: ${({theme}) => theme.palette.common.brown};
    box-shadow: none;
  }
  &.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
    background-color: ${({theme}) => theme.palette.common.brown};
  }
  &.ant-picker-now {
    padding-left: 70px;
  }
`;
