import styled from 'styled-components';

export const StyledSpan = styled.span`
  font-size: 12px;
`;

export const TooltipWrapper = styled.div`
  margin-left: 5px;
`;

export const CheckboxLabelWrapper = styled.div`
  display: flex;
  align-items: center;
`;
