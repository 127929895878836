import React, {useEffect, useState} from 'react';
import {Table, TableTitle} from '../styles';
import {TableWrapper} from '../../../ui-kit/Table';
import {ConfigsTableColumnsTitles, ConfigsTableFields, TableColumnT} from '../../../helpers/table';
import {ActionsItem, ActionsItemProps, TicketTypesItem, TicketTypesProps} from './TableItems';
import {Modal} from 'antd';
import {ModalDescr} from '../../Dashboard/ui/desktop/styles';
import {QuestionCircleOutlined} from '@ant-design/icons';
import {ConfigTableItemT, ModalLvl, TicketTypes} from '../../../types/settings';

const ticketConfigItem1 = {
  id: '1',
  types: TicketTypes.skating,
  actions: {id: '1'},
};

export const TicketTable = () => {
  const [focusedId, setFocusedId] = useState<string | undefined>('');
  const [isEditing, setIsEditing] = useState(false);
  const [delModalLvl, setDelModalLvl] = useState<ModalLvl>(ModalLvl.closed);
  const setDelPopup = (lvl: ModalLvl) => setDelModalLvl(lvl);
  const setFocusId = (id?: string) => setFocusedId(id);
  const setEditing = (b: boolean) => setIsEditing(b);

  const columns: TableColumnT[] = [
    {
      key: ConfigsTableFields.types,
      dataIndex: ConfigsTableFields.types,
      title: ConfigsTableColumnsTitles.types,
      render: (props: TicketTypesProps) => <TicketTypesItem {...props} configType={'ticket'} />,
    },
    {
      key: ConfigsTableFields.actions,
      dataIndex: ConfigsTableFields.actions,
      title: ConfigsTableColumnsTitles.action,
      render: (props: ActionsItemProps) => (
        <ActionsItem
          {...props}
          configType={'ticket'}
          setDelPopup={setDelPopup}
          setFocusId={setFocusId}
          setEditing={setEditing}
          focusedId={focusedId}
          isEditing={isEditing}
        />
      ),
    },
  ];

  useEffect(() => {
    if (delModalLvl === ModalLvl.confirm) {
      Modal.confirm({
        title: `You are request to remove {ticketName}?`,
        content: <ModalDescr>Upon confirming, the ticket will be removed from the system.</ModalDescr>,
        okText: 'Yes',
        cancelText: 'No',
        autoFocusButton: null,
        icon: <QuestionCircleOutlined />,
        onOk: () => setDelPopup(ModalLvl.success),
        onCancel: () => setDelPopup(ModalLvl.closed),
      });
    }
    if (delModalLvl === ModalLvl.success) {
      Modal.success({
        title: `{ticketName} is removed!`,
        content: <ModalDescr>The ticket has been removed from the system.</ModalDescr>,
        okText: 'Done',
        onOk: () => {
          console.log('delete', focusedId);
          setDelPopup(ModalLvl.closed);
        },
      });
    }
  }, [delModalLvl]);
  const ticketData: ConfigTableItemT[] = [
    // {
    //   types: {addRow: true},
    //   actions: {addRow: true},
    // },
    {
      id: ticketConfigItem1,
      types: ticketConfigItem1,
      actions: {id: ticketConfigItem1.id},
    },
  ];
  return (
    <>
      <TableTitle level={3}>Ticket</TableTitle>
      <TableWrapper>
        <Table columns={columns} dataSource={ticketData} pagination={false} />
      </TableWrapper>
    </>
  );
};
