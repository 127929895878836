import React from 'react';
import {PageWrapper} from './desktop/styles';
import {DashboardPageProps} from '../types';
import {DesktopDashboard} from './desktop/DesktopDashboard';
import {isMobile} from '../../../ui-kit/theme/breakpoints';
import {MobileDashborad} from './mobile';

export const DashboardPage: React.FC<DashboardPageProps> = (props) => {
  return <PageWrapper>{!isMobile ? <DesktopDashboard {...props} /> : <MobileDashborad {...props} />}</PageWrapper>;
};
