import {SelectOptionT} from '../types/helpers';

export const messageTypeOptions: SelectOptionT[] = [
  {label: 'Scheduled', value: 'scheduled'},
  {label: 'Instant', value: 'instant'},
];

export const messageTypes = {
  instant: 'instant',
  scheduled: 'scheduled',
};
export const notificationModeOptions = [
  {label: 'SMS', value: 'sms'},
  {label: 'Email', value: 'email'},
];

export const ticketStatusOptions = [
  {label: 'All', value: 'all'},
  {label: 'Redeemed', value: 'redeemed'},
  {label: 'Unredeemed', value: 'unredeemed'},
];

export const ticketStatusTypes = {
  all: 'all',
  redeemed: 'redeemed',
  unredeemed: 'unredeemed',
};

export const havingTicketsOptions = [
  {label: 'General Admission', value: 'General Admission'},
  {label: 'Ice Skating', value: 'Ice Skating'},
  {label: 'Parking', value: 'Parking'},
  {label: 'Preferred Parking', value: 'Preferred Parking'},
  {label: 'Valet Parking', value: 'Valet Parking'},
  {label: 'Parking - Garage C', value: 'Parking - Garage C'},
  {label: 'Parking - Geico Garage', value: 'Parking - Geico Garage'},
  {label: 'Parking - Audi Lot Preferred', value: 'Parking - Audi Lot Preferred'},
  {label: 'VIP Experience', value: 'VIP Experience'},
  {label: 'Private Suites', value: 'Private Suites'},
  {label: 'Dinner', value: 'Dinner'},
  {label: 'Paws n Claus', value: 'Paws n Claus'},
  {label: 'Ladies Night', value: 'Ladies Night'},
  {label: 'Night of Giving', value: 'Night of Giving'},
  {label: 'Snow Tubing', value: 'Snow Tubing'},
];

export const defaultSubject = 'Important Update from Enchant';
