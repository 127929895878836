export enum ModalLvl {
  closed = 'closed',
  confirm = 'confirm',
  success = 'success',
}

export enum DepartmentsList {
  sales = 'Sales',
  labs = 'Labs',
  marketing = 'Marketing',
  village = 'Village',
  executives = 'Executives',
  operations = 'Operations',
  build = 'Build',
}

export enum Roles {
  admin = 'admin',
  manager = 'manager',
  associate = 'associate',
}

export enum DepTicketTypes {
  labs = 'labs',
  marketing = 'marketing',
  sales = 'sales',
  services = 'services',
}

export enum TicketTypes {
  skating = 'iceSkating',
  parking = 'parking',
  sales = 'sales',
}

export enum AccessPermissions {
  promo = 'Promo Code',
  ticket = 'Group Ticket',
  notifications = 'Notification',
}

export const RolesLabels = {
  [Roles.admin]: 'Admin',
  [Roles.manager]: 'Dep. Manager',
  [Roles.associate]: 'Associate',
};

export const TicketTypesLabels = {
  [DepTicketTypes.labs]: 'Labs',
  [DepTicketTypes.marketing]: 'Marketing',
  [DepTicketTypes.sales]: 'Sales',
  [DepTicketTypes.services]: 'Guest Services',
  [TicketTypes.skating]: 'Ice Skating',
  [TicketTypes.parking]: 'Parking',
  [TicketTypes.sales]: 'Sales',
};

export type ManageItemT = {
  id?: string;
  fullName?: string;
  email?: string;
  role?: string;
  roleId?: string;
  department?: string;
  departmentId?: string;
  access?: string[];
  addRow?: boolean;
  isEditing?: boolean | any;
  focusedId?: string | undefined;
  setFocusId?: (id?: string) => void;
  setEditing?: (b: boolean) => void;
  setDelPopup?: (lvl: ModalLvl) => void;
};

export type ConfigTypeT = 'role' | 'department' | 'ticket' | 'permission' | null;

export type ConfigItemT = {
  id?: string;
  department?: DepartmentsList;
  roles?: Roles;
  view?: boolean;
  manageGroup?: boolean;
  manageDistr?: boolean;
  types?: DepTicketTypes | TicketTypes;
  addRow?: boolean;
  isEditing?: boolean;
  setDelPopup?: (lvl: ModalLvl) => void;
  setFocusId?: (id?: string) => void;
  setEditing?: (b: boolean) => void;
  focusedId?: string | undefined;
  configType?: ConfigTypeT;
};

export type ManageTableItemT = {
  id?: ManageItemT;
  fullName?: ManageItemT;
  email?: ManageItemT;
  role?: ManageItemT;
  department?: ManageItemT;
  access?: ManageItemT;
  actions?: ManageItemT;
};

export type ConfigTableItemT = {
  id?: ConfigItemT;
  role?: ConfigItemT;
  view?: ConfigItemT;
  manageGroup?: ConfigItemT;
  manageDistr?: ConfigItemT;
  types?: ConfigItemT;
  actions?: ConfigItemT;
};

//-------DEPARTMENT----------

export type DepItemT = {
  id?: string | number;
  name?: string;
  description?: string;
  addRow?: boolean;
  isEditing?: boolean | any;
  focusedId?: string | undefined;
  setFocusId?: (id?: string) => void;
  setEditing?: (b: boolean) => void;
  setDelPopup?: (lvl: ModalLvl) => void;
};

export type DepTableItemT = {
  id?: DepItemT;
  name?: DepItemT;
  actions?: DepItemT;
};

//-------ROLE----------

export type RoleItemT = {
  id?: string | number;
  name?: string;
  addRow?: boolean;
  isEditing?: boolean | any;
  focusedId?: string | undefined;
  setFocusId?: (id?: string) => void;
  setEditing?: (b: boolean) => void;
  setDelPopup?: (lvl: ModalLvl) => void;
};

export type RoleTableItemT = {
  id?: RoleItemT;
  name?: RoleItemT;
  actions?: RoleItemT;
};

//-------PERMISSIONS----------

export type PermissionItemT = {
  id?: string | number;
  name?: string;
  description?: string;
  addRow?: boolean;
  isEditing?: boolean | any;
  focusedId?: string | undefined;
  setFocusId?: (id?: string) => void;
  setEditing?: (b: boolean) => void;
  setDelPopup?: (lvl: ModalLvl) => void;
};

export type PermissionTableItemT = {
  id?: PermissionItemT;
  name?: PermissionItemT;
  description?: PermissionItemT;
  actions?: PermissionItemT;
};

/// <-user

export type CreatingUserStateT = {
  name?: string;
  email?: string;
  roleId?: string;
  departmentId?: string;
  access?: string[];
};
export type CreatingUserFields = 'name' | 'email' | 'roleId' | 'departmentId' | 'access';

/// user ->
