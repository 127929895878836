import styled from 'styled-components';
import {ButtonTypes, LinkButton} from '../../../../ui-kit/Button';
import {Typography} from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
const {Text} = Typography;

export const CreateButton = styled(LinkButton).attrs({
  type: 'primary',
})`
  border-radius: 4px;
`;

export const ManageButton = styled(LinkButton).attrs({
  variant: ButtonTypes.secondary,
})`
  border-radius: 4px;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  margin-top: 8px;
`;

export const TitleText = styled(Text)`
  font-size: 20px;
  font-weight: 600;
`;

export const StatsWrapper = styled.div`
  margin-top: 40px;
  padding: 8px 12px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: ${({theme}) => theme.palette.background.gray};
  width: 100%;
  border-radius: 4px;
`;

export const GroupStatWrapper = styled.div<{$showMore?: boolean}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: ${({$showMore}) => ($showMore ? '8px' : '24px')};
  flex-direction: ${({$showMore}) => ($showMore ? 'column' : 'row')};
  ${({$showMore}) => $showMore && 'width: 100%'};
`;

export const GroupCardStatWrapper = styled.div<{$showMore?: boolean}>`
  ${({$showMore}) => $showMore && 'width: 100%'};
  display: flex;
  flex-direction: ${({$showMore}) => ($showMore ? 'row' : 'column')};
  align-items: ${({$showMore}) => ($showMore ? 'center' : 'flex-start')};
  justify-content: ${({$showMore}) => ($showMore ? 'space-between' : 'center')};
  padding: 0px;
`;

export const CardStatWrapper = styled.div`
  width: 100%;
`;

export const CardStatTitle = styled(Text)<{$showMore?: boolean}>`
  width: 100%;
  font-size: ${({$showMore}) => ($showMore ? '16px' : '12px')};
  color: #9a9fa2;
  white-space: pre;
`;

export const CardStatValue = styled(Text)<{$showMore?: boolean}>`
  font-size: ${({$showMore}) => ($showMore ? '16px' : '14px')};
  color: ${({theme}) => theme.palette.common.primaryBlack};
  font-weight: ${({$showMore}) => ($showMore ? '700' : '400')};
  white-space: pre;
`;

export const ViewMoreStat = styled(Text)`
  font-size: 12px;
  color: ${({theme}) => theme.palette.common.primaryBlack};
  font-weight: 700;
`;

export const NotificationWrapper = styled.div`
  margin: 12px 0px;
`;

export const NotificationCount = styled(Text)`
  font-size: 12px;
  font-weight: 700;
`;

export const NotificationCardWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2px;
  border: 1px solid ${({theme}) => theme.palette.common.systemGray};
  border-radius: 4px;
`;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
export const InfiniteScrollListWrapper = styled(InfiniteScroll)`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 12px 0px;
  margin-bottom: 60px;
`;

export const NotificationTitle = styled(Text)`
  font-size: 15px;
  font-weight: 700;
  text-transform: capitalize;
`;

export const NotificationDetailsText = styled(Text)`
  margin-top: 2px;
  font-size: 12px;
  font-weight: 400;
  color: #9a9fa2;
  text-transform: uppercase;
`;

export const NotificationDetailsItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const NotificationDetailsItemLabel = styled(Text)`
  font-size: 16px;
  font-weight: 400;
  color: ${({theme}) => theme.palette.common.darkGray};
`;

export const NotificationDetailsItemValue = styled(NotificationDetailsItemLabel)`
  font-weight: 700;
  text-transform: capitalize;
`;

export const NotificationDetailsLocationItem = styled.div`
  display: flex;
  padding: 0px 8px;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  border-radius: 2px;
  background-color: ${({theme}) => theme.palette.background.lightGold};
`;

export const NotificationDetailsLocationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 12px;
`;

export const ScrollToWrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid ${({theme}) => theme.palette.common.systemGray};
  background-color: ${({theme}) => theme.palette.background.white};
  bottom: 0;
  left: 0;
`;

export const ScrollToText = styled(Text)`
  font-size: 14px;
  font-weight: 400;
  color: ${({theme}) => theme.palette.common.darkGray};
  margin-right: 8px;
`;

export const ModalDescr = styled(Text)`
  font-size: 14px;
  line-height: 22px;
  display: block;
`;

export const MenuMoreWrapper = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
`;
