import {Divider} from 'antd';
import React from 'react';
import {route} from '../../../constants/routes';
import {ManageTableDataT} from '../../../types/messages';
import {LinkBack} from '../../../ui-kit/Button/LinkBack';
import {ManageTemplateWrapper, PageWrapper, TitleLine, GroupTitle, CreateButton} from './styles';
import {ManageTemplatesTable} from './Table';

export type ManageTemplatesProps = {
  onDelete: (id: string) => void;
  templates: ManageTableDataT[];
  goEdit: (id: string) => void;
};

export const ManageTemplates: React.FC<ManageTemplatesProps> = ({templates, onDelete, goEdit}) => {
  return (
    <PageWrapper>
      <TitleLine>
        <LinkBack to={route.dashboard.path}>Back to Dashboard</LinkBack>
        <CreateButton to={route.createNewTemplate.path}>Create New Template</CreateButton>
      </TitleLine>
      <ManageTemplateWrapper>
        <GroupTitle>Manage Templates</GroupTitle>
        <Divider />
        <ManageTemplatesTable templates={templates} onDelete={onDelete} goEdit={goEdit} />
      </ManageTemplateWrapper>
    </PageWrapper>
  );
};
