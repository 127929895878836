import React from 'react';
import {DashboardCardT} from '../../../../types/dashboard';
import {DashboardPageProps} from '../../types';
import {
  CreateButton,
  DashboardCard,
  DashboardHeaderWrapper,
  ManageButton,
  PaginationWrapper,
  RightCardsWrapper,
} from './styles';
import {Pagination} from 'antd';
import {DashboardTable} from './Table';
import {route} from '../../../../constants/routes';

const pageSizeOptions = [10, 25, 50, 100];

const CardsMock: DashboardCardT[] = [
  {
    label: 'SMS Groups',
    value: '10',
  },
  {
    label: 'SMS Sent',
    value: '40,5677',
  },
  {
    label: 'Email Groups',
    value: '10',
  },
  {
    label: 'Emails Sent',
    value: '40,5677',
  },
];

export const DesktopDashboard: React.FC<DashboardPageProps> = ({
  goEdit,
  onDelete,
  pagination,
  searchNotifications,
  isSearching,
  loading,
  notifications,
}) => {
  return (
    <>
      <DashboardHeaderWrapper>
        <Cards cards={CardsMock} />
        <div>
          <ManageButton to={route.manageTemplates.path}>Manage Templates</ManageButton>
          <CreateButton to={route.createNewMessage.path}>Create New Message</CreateButton>
        </div>
      </DashboardHeaderWrapper>
      <DashboardTable
        data={notifications}
        searchNotifications={searchNotifications}
        goEdit={goEdit}
        onDelete={onDelete}
        loading={loading}
      />
      <PaginationWrapper>
        {!isSearching && (
          <Pagination
            showSizeChanger
            onShowSizeChange={pagination.changeLimit}
            current={pagination.pageNumber}
            onChange={pagination.changePage}
            total={pagination.total}
            pageSizeOptions={pageSizeOptions}
          />
        )}
      </PaginationWrapper>
    </>
  );
};

const Cards: React.FC<{cards: DashboardCardT[]}> = ({cards}) => {
  return (
    <RightCardsWrapper>
      {cards.map((el, i) => (
        <DashboardCard label={el.label} value={el.value} tooltip={el.tooltip} key={el?.label || i} />
      ))}
    </RightCardsWrapper>
  );
};
