import React from 'react';
import {useParams} from 'react-router-dom';
import {TemplateForm} from '../../components/Template/Form';
import {toInitialTemplateState} from '../../helpers/templates';
import {useUpdateTemplate, useGetTemplate} from '../../hooks/templates';

export const EditTemplate = () => {
  const {id} = useParams();
  const formData = useUpdateTemplate(id);
  const {template, loading} = useGetTemplate(id);
  const initial = toInitialTemplateState(template);

  return <TemplateForm mode={'edit'} formData={formData} initial={initial} isLoading={loading} />;
};
