import React from 'react';
import {ConfigItemT, DepItemT, ModalLvl, PermissionItemT, RoleItemT, TicketTypesLabels} from '../../../types/settings';
import {ButtonTypes} from '../../../ui-kit/Button';
import {CustomCheckbox} from '../../../ui-kit/Checkbox';
import {
  ConfigurationDepartmentWrapper,
  ActionsButtons,
  ActionBtn,
  RemoveBtn,
  ConfigurationDepartmentInput,
  ItemWrapper,
  TypeInput,
  StyledSpan,
} from '../styles';
import {Tooltip} from '../../../ui-kit/Tooltip';
import {palette} from '../../../ui-kit/theme/palette';
import {Typography} from 'antd';
const {Text} = Typography;

export type DepartmentItemProps = ConfigItemT;

export const DepartmentItem: React.FC<DepartmentItemProps> = ({department, addRow, isEditing, focusedId, id}) => {
  const isCurrentEditing = isEditing ? focusedId === id : false;

  return (
    <ConfigurationDepartmentWrapper>
      {addRow ? (
        <ConfigurationDepartmentInput placeholder="Type name here..." />
      ) : isCurrentEditing ? (
        <ConfigurationDepartmentInput placeholder="Type name here..." />
      ) : (
        department
      )}
    </ConfigurationDepartmentWrapper>
  );
};

export type TicketTypesProps = ConfigItemT;

export const TicketTypesItem: React.FC<TicketTypesProps> = ({types, addRow, configType}) => {
  const type = types && TicketTypesLabels[types];
  return (
    <ItemWrapper>
      {addRow ? (
        <TypeInput placeholder={configType === 'department' ? 'Type Department' : 'Add type'} />
      ) : (
        <span>{type}</span>
      )}
    </ItemWrapper>
  );
};

export type DepartmentTypesProps = DepItemT & {
  addName?: string;
  onChange: (e: any) => void;
};

export const DepartmentTypesItem: React.FC<DepartmentTypesProps> = ({addName, onChange, addRow, name}) => {
  return (
    <ItemWrapper>
      {addRow ? <TypeInput value={addName} onChange={onChange} placeholder={'Type Department'} /> : <span>{name}</span>}
    </ItemWrapper>
  );
};
export type ViewItemProps = ConfigItemT;

export const ViewItem: React.FC<ViewItemProps> = ({view}) => {
  if (view) {
    return <CustomCheckbox checked />;
  }

  return <CustomCheckbox />;
};

export type ManageGroupProps = ConfigItemT;

export const ManageGroupItem: React.FC<ManageGroupProps> = ({manageGroup}) => {
  if (manageGroup) {
    return <CustomCheckbox checked />;
  }

  return <CustomCheckbox />;
};

export type ManageDistrProps = ConfigItemT;

export const ManageDistrItem: React.FC<ManageDistrProps> = ({manageDistr}) => {
  if (manageDistr) {
    return <CustomCheckbox checked />;
  }

  return <CustomCheckbox />;
};

export type ActionsItemProps = ConfigItemT & {
  onAdd?: () => void;
};

export const ActionsItem: React.FC<ActionsItemProps> = ({addRow, onAdd, id, setFocusId, setEditing, setDelPopup}) => {
  return null;
  const onDelete = () => {
    setFocusId?.(id);
    setEditing?.(false);
    setDelPopup?.(ModalLvl.confirm);
  };
  return (
    <ActionsButtons>
      {addRow ? (
        <ActionBtn variant={ButtonTypes.secondary} onClick={onAdd}>
          Add
        </ActionBtn>
      ) : (
        <RemoveBtn variant={ButtonTypes.secondary} onClick={onDelete}>
          Delete
        </RemoveBtn>
      )}
    </ActionsButtons>
  );
};

type TitleWithTooltipProps = {
  label: string;
  title?: string | React.ReactNode;
};

export const TitleWithTooltip: React.FC<TitleWithTooltipProps> = ({label, title}) => {
  return (
    <>
      <StyledSpan>{label}</StyledSpan>
      <Tooltip title={<Text>{title}</Text>} color={palette.common.white} />
    </>
  );
};

export type PermissionNameProps = PermissionItemT & {
  addName?: string;
  onChange: (e: any) => void;
};

export const PermissionNameItem: React.FC<PermissionNameProps> = ({addName, onChange, addRow, name}) => {
  return (
    <ItemWrapper>
      {addRow ? <TypeInput value={addName} onChange={onChange} placeholder={'Permission'} /> : <span>{name}</span>}
    </ItemWrapper>
  );
};

export type PermissionDescrProps = PermissionItemT & {
  addDescription?: string;
  onChange: (e: any) => void;
};

export const PermissionDescrItem: React.FC<PermissionDescrProps> = ({
  onChange,
  addRow,
  description,
  addDescription,
}) => {
  return (
    <ItemWrapper>
      {addRow ? (
        <TypeInput value={addDescription} onChange={onChange} placeholder={'Description'} />
      ) : (
        <span>{description}</span>
      )}
    </ItemWrapper>
  );
};

export type RoleNameProps = RoleItemT & {
  addName?: string;
  onChange: (e: any) => void;
};

export const RoleNameItem: React.FC<RoleNameProps> = ({addName, onChange, addRow, name}) => {
  return (
    <ItemWrapper>
      {addRow ? <TypeInput value={addName} onChange={onChange} placeholder={'Role'} /> : <span>{name}</span>}
    </ItemWrapper>
  );
};

type ManagePermissionItemProps = RoleItemT & {
  checked?: boolean;
};

export const ManagePermissionItem: React.FC<ManagePermissionItemProps> = ({checked}) => (
  <CustomCheckbox checked={checked} />
);
