import {useCreateMessageT} from '../../../hooks/messages';
import {TemplatesT, notificationBody} from '../../../queries/types';
import {FormMode, ManageTableDataT} from '../../../types/messages';

export type MessageFormProps = {
  mode: FormMode;
  formData: useCreateMessageT;
  templates: ManageTableDataT[];
  initial?: Partial<notificationBody>;
  loading?: boolean;
  getTemplate?: (id?: string) => void;
  selectedTemplate?: TemplatesT;
  goDashboard: () => void;
};

export const steps = {
  notification: 0,
  audience: 1,
  message: 2,
  review: 3,
};

export type LocationOptionsT = {
  id: string;
  label: string;
  value: string;
}[];

export type TemplateOptionsT = {
  label: string | undefined;
  value: string;
}[];

export type TextLImitTypeT = {
  limit: 999 | 1000;
  content: string;
};
