import React from 'react';
import {
  PreviewModalWrapper,
  PreviewModalTitle,
  TitleDescription,
  OptionRow,
  PreviewContentBlock,
  OptionRowTitle,
  OptionRowValue,
  MessageWrapper,
  SubjectWrapper,
  ErrorLabel,
} from './modalStyles';
import moment from 'moment';
import './modal.css';
import {LocationOptionsT} from '../../types';

type PreviewModalPropsT = {
  values: any;
  guests: number | null;
  numTickets: number | null;
  locations: LocationOptionsT;
  nonValidScheduled?: boolean;
};

export const PreviewModal: React.FC<PreviewModalPropsT> = ({
  values,
  guests,
  numTickets,
  locations,
  nonValidScheduled,
}) => {
  const visitingFrom = `${moment(values?.visitingFromDate).format('ll')} at ${moment(values?.visitingFromTime).format(
    'LT',
  )}`;
  const visitingUntil = `${moment(values?.visitingUntilDate).format('ll')} at ${moment(
    values?.visitingUntilTime,
  ).format('LT')}`;

  const scheduledOn = `${moment(values?.scheduledDate).format('ll')} at ${moment(values?.scheduledTime).format('LT')}`;
  const ticketStatus = values.orderStatus === 'all' ? 'All tickets' : values.orderStatus;
  const excluded = values?.excludeGuestsHavingTickets ? values?.excludeGuestsHavingTickets.join(', ') : 'None';
  return (
    <PreviewModalWrapper>
      <PreviewModalTitle>Summary of New Message</PreviewModalTitle>
      <TitleDescription>Please review the summary of your new message before sending it out.</TitleDescription>
      <PreviewContentBlock>
        <OptionRow>
          <OptionRowTitle>Location</OptionRowTitle>
          <OptionRowValue>{locations.find((item) => item.id === values.guestsInLocation)?.label || []}</OptionRowValue>
        </OptionRow>
        <OptionRow>
          <OptionRowTitle>Ticket Status</OptionRowTitle>
          <OptionRowValue>{ticketStatus}</OptionRowValue>
        </OptionRow>
        <OptionRow>
          <OptionRowTitle>Exclude Ticket Type</OptionRowTitle>
          <OptionRowValue>{excluded}</OptionRowValue>
        </OptionRow>
        <OptionRow>
          <OptionRowTitle>Ticket Type</OptionRowTitle>
          <OptionRowValue>{values.guestsHavingTickets}</OptionRowValue>
        </OptionRow>
        <OptionRow>
          <OptionRowTitle>Notification Mode</OptionRowTitle>
          <OptionRowValue>{values.notificationMode?.join(', ')}</OptionRowValue>
        </OptionRow>
        <OptionRow>
          <OptionRowTitle>Total Guests</OptionRowTitle>
          <OptionRowValue>{guests}</OptionRowValue>
        </OptionRow>
        <OptionRow>
          <OptionRowTitle>Total Tickets</OptionRowTitle>
          <OptionRowValue>{numTickets || 0}</OptionRowValue>
        </OptionRow>
        <OptionRow>
          <OptionRowTitle>Event Start Date & Time</OptionRowTitle>
          <OptionRowValue>{visitingFrom}</OptionRowValue>
        </OptionRow>
        <OptionRow>
          <OptionRowTitle>Event End Date & Time</OptionRowTitle>
          <OptionRowValue>{visitingUntil}</OptionRowValue>
        </OptionRow>
        {!!scheduledOn && (
          <OptionRow>
            <OptionRowTitle>Message Send Date & Time</OptionRowTitle>
            <OptionRowValue $isError={nonValidScheduled}>
              {scheduledOn}
              {nonValidScheduled && <ErrorLabel>This time in the past</ErrorLabel>}
            </OptionRowValue>
          </OptionRow>
        )}
      </PreviewContentBlock>
      {(values.allowDateChange || values.allowRefund) && (
        <PreviewContentBlock>
          {values.allowDateChange && (
            <OptionRow>
              <OptionRowTitle>Date Change Allowed?</OptionRowTitle>
              <OptionRowValue>Yes</OptionRowValue>
            </OptionRow>
          )}
          {values.allowRefund && (
            <OptionRow>
              <OptionRowTitle>Refund Allowed?</OptionRowTitle>
              <OptionRowValue>Yes</OptionRowValue>
            </OptionRow>
          )}
        </PreviewContentBlock>
      )}
      <PreviewContentBlock>
        {values.subject && (
          <>
            <SubjectWrapper>
              <OptionRowTitle>Subject</OptionRowTitle>
            </SubjectWrapper>
            <OptionRowValue>{values.subject}</OptionRowValue>
          </>
        )}
        <MessageWrapper>
          <OptionRowTitle>Message</OptionRowTitle>
        </MessageWrapper>
        <OptionRowValue>{values.message}</OptionRowValue>
      </PreviewContentBlock>
    </PreviewModalWrapper>
  );
};
