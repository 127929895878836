import React from 'react';
import {useNavigate} from 'react-router-dom';
import {ManageTemplates} from '../../components/Template/Manage';
import {route} from '../../constants/routes';
import {useDeleteTemplate, useGetListTemplatesTable} from '../../hooks/templates';

export const Manage = () => {
  const {templates, refetch} = useGetListTemplatesTable();
  const {onDelete} = useDeleteTemplate(refetch);
  const navigate = useNavigate();
  const goEdit = (id: string) => navigate(route.editTemplate.get({id}));

  return <ManageTemplates templates={templates} onDelete={onDelete} goEdit={goEdit} />;
};
