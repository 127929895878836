import React from 'react';
import {
  ErrorLabel,
  MessageWrapper,
  NextButton,
  OptionRow,
  OptionRowTitle,
  OptionRowValue,
  PreviewContentBlock,
  PreviewSendWrapper,
  PreviewWrapper,
  RewiewSubtitle,
  RewiewTitle,
  SectionTitleText,
  SectionTitleWrapper,
  SubjectWrapper,
} from '../styles';
import {LocationOptionsT} from '../../../types';
import moment from 'moment';
import {FormInstance} from 'antd';

type PreviewModalPropsT = {
  form: FormInstance;
  guests: number | null;
  numTickets: number | null;
  locations: LocationOptionsT;
  onCreate: () => void;
};

export const Review: React.FC<PreviewModalPropsT> = ({guests, locations, numTickets, form, onCreate}) => {
  const values = form.getFieldsValue();
  const today = moment.utc(new Date());
  const hours = values?.scheduledTime?.hours();
  const minutes = values?.scheduledTime?.minutes();
  const _s = values?.scheduledDate;
  if (_s) {
    _s.set('hours', hours);
    _s.set('minutes', minutes);
  }
  const diff_minutes = today.diff(_s, 'minutes');
  const nonValidScheduled = values?.scheduledDate ? diff_minutes >= 0 : false;
  const visitingFrom = `${moment(values?.visitingFromDate).format('ll')} at ${moment(values?.visitingFromTime).format(
    'LT',
  )}`;
  const visitingUntil = `${moment(values?.visitingUntilDate).format('ll')} at ${moment(
    values?.visitingUntilTime,
  ).format('LT')}`;

  const scheduledOn = `${moment(values?.scheduledDate).format('ll')} at ${moment(values?.scheduledTime).format('LT')}`;
  const ticketStatus = values.orderStatus === 'all' ? 'All tickets' : values.orderStatus;
  const excluded = values?.excludeGuestsHavingTickets ? values?.excludeGuestsHavingTickets.join(', ') : 'None';
  return (
    <>
      <PreviewWrapper>
        <div>
          <RewiewTitle>Review</RewiewTitle>
          <RewiewSubtitle>Review the message you would like to broadcast.</RewiewSubtitle>
        </div>
        <PreviewContentBlock>
          <SectionTitleWrapper>
            <SectionTitleText>Message</SectionTitleText>
          </SectionTitleWrapper>
          {values.subject && (
            <>
              <SubjectWrapper>
                <OptionRowTitle>Subject</OptionRowTitle>
                <OptionRowValue>{values.subject}</OptionRowValue>
              </SubjectWrapper>
            </>
          )}
          <MessageWrapper>
            <OptionRowTitle>Message</OptionRowTitle>
          </MessageWrapper>
          <OptionRowValue>{values.message}</OptionRowValue>
        </PreviewContentBlock>
        <PreviewContentBlock>
          <SectionTitleWrapper>
            <SectionTitleText>Audience</SectionTitleText>
          </SectionTitleWrapper>
          <OptionRow>
            <OptionRowTitle>Location</OptionRowTitle>
            <OptionRowValue>
              {locations.find((item) => item.id === values.guestsInLocation)?.label || []}
            </OptionRowValue>
          </OptionRow>
          <OptionRow>
            <OptionRowTitle>Ticket Type</OptionRowTitle>
            <OptionRowValue>{values.guestsHavingTickets}</OptionRowValue>
          </OptionRow>
          <OptionRow>
            <OptionRowTitle>Notification Mode</OptionRowTitle>
            <OptionRowValue>{values.notificationMode?.join(', ')}</OptionRowValue>
          </OptionRow>
          <OptionRow>
            <OptionRowTitle>Ticket Status</OptionRowTitle>
            <OptionRowValue>{ticketStatus}</OptionRowValue>
          </OptionRow>
          <OptionRow>
            <OptionRowTitle>Exclude Ticket Type</OptionRowTitle>
            <OptionRowValue>{excluded}</OptionRowValue>
          </OptionRow>
          <OptionRow>
            <OptionRowTitle>Total Guests</OptionRowTitle>
            <OptionRowValue>{guests}</OptionRowValue>
          </OptionRow>
          <OptionRow>
            <OptionRowTitle>Total Tickets</OptionRowTitle>
            <OptionRowValue>{numTickets || 0}</OptionRowValue>
          </OptionRow>
          <OptionRow>
            <OptionRowTitle>Event Start</OptionRowTitle>
            <OptionRowValue>{visitingFrom}</OptionRowValue>
          </OptionRow>
          <OptionRow>
            <OptionRowTitle>Event End</OptionRowTitle>
            <OptionRowValue>{visitingUntil}</OptionRowValue>
          </OptionRow>
          {(values.allowDateChange || values.allowRefund) && (
            <>
              {values.allowDateChange && (
                <OptionRow>
                  <OptionRowTitle>Date Change</OptionRowTitle>
                  <OptionRowValue>Yes</OptionRowValue>
                </OptionRow>
              )}
              {values.allowRefund && (
                <OptionRow>
                  <OptionRowTitle>Refund Allowed</OptionRowTitle>
                  <OptionRowValue>Yes</OptionRowValue>
                </OptionRow>
              )}
            </>
          )}
          {!!scheduledOn && (
            <OptionRow>
              <OptionRowTitle>Send Date</OptionRowTitle>
              <OptionRowValue $isError={nonValidScheduled}>
                {scheduledOn}
                {nonValidScheduled && <ErrorLabel>This time in the past</ErrorLabel>}
              </OptionRowValue>
            </OptionRow>
          )}
        </PreviewContentBlock>
      </PreviewWrapper>
      <PreviewSendWrapper>
        <NextButton
          $isAbsolute={false}
          $disabled={nonValidScheduled}
          onClick={nonValidScheduled ? undefined : onCreate}>
          send message
        </NextButton>
      </PreviewSendWrapper>
    </>
  );
};
