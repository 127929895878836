import styled from 'styled-components';
import {NotificationTable} from '../../Dashboard/ui/desktop/styles';
import {Media} from '../../../ui-kit/theme/breakpoints';
import {Typography} from 'antd';
import {LinkButton} from '../../../ui-kit/Button';

const {Text} = Typography;

export const ManageTemplateWrapper = styled.div`
  padding: 18px 0 21px 0;
  box-sizing: border-box;
  border: 1px solid ${({theme}) => theme.palette.background.darkGray};
  border-radius: 8px;
  background-color: ${({theme}) => theme.palette.background.white};
  min-height: 600px;
`;

export const ManageTemplateTable = styled(NotificationTable)`
  & .ant-table-container table > thead > tr:first-child th:last-child {
    width: 65px;
  }
`;

export const GroupTitle = styled(Text)`
  font-weight: ${({theme}) => theme.font.weight.bold};
  font-size: 20px;
  line-height: 28px;
  display: block;
  margin-bottom: 22px;
  margin-left: 22px;
`;

export const ModalDescr = styled(Text)`
  font-size: 14px;
  line-height: 22px;
  display: block;
`;

export const CreateButton = styled(LinkButton).attrs({
  type: 'primary',
})``;

export const TitleLine = styled.div`
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const PageWrapper = styled.div`
  width: 100%;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  padding: 64px 190px 32px 190px;
  ${Media.down.xxl} {
    padding: 54px 90px 32px 90px;
  }
`;
