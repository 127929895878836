export type FormMode = 'create' | 'edit';

export enum MessageTypes {
  scheduled = 'scheduled',
  sent = 'sent',
  instant = 'instant',
}

export type MessageCreateFormValues = {
  name?: string;
  notificationType?: string;
  eventStartDate?: string;
  eventEndDate?: string;
  scheduledDate?: string;
  scheduledTime?: string;
  notificationMode?: string[];
  guestsInLocation?: string;
  guestsHavingTickets?: string;
  excludeGuestsHavingTickets?: string[];
  orderStatus?: string;
  allowDateChange?: boolean;
  allowRefund?: boolean;
  visitingFromDate?: string;
  visitingFromTime?: string;
  visitingUntilDate?: string;
  visitingUntilTime?: string;
  eventTemplateName?: {
    include: string[];
    exclude: string[];
  };
  subject?: string;
  message?: string;
  description?: string;
  eventSellerId?: string[];
};

export enum MessageCreateFields {
  name = 'name',
  notificationType = 'notificationType',
  eventStartDate = 'eventStartDate',
  eventEndDate = 'eventEndDate',
  scheduledDate = 'scheduledDate',
  scheduledTime = 'scheduledTime',
  notificationMode = 'notificationMode',
  guestsInLocation = 'guestsInLocation',
  guestsHavingTickets = 'guestsHavingTickets',
  excludeGuestsHavingTickets = 'excludeGuestsHavingTickets',
  orderStatus = 'orderStatus',
  allowDateChange = 'allowDateChange',
  allowRefund = 'allowRefund',
  visitingFromDate = 'visitingFromDate',
  visitingFromTime = 'visitingFromTime',
  visitingUntilDate = 'visitingUntilDate',
  visitingUntilTime = 'visitingUntilTime',
  eventTemplateName = 'eventTemplateName',
  message = 'message',
  subject = 'subject',
  description = 'description',
  eventSellerId = 'eventSellerId',
}

export type TemplateCreateFormValues = {
  name?: string;
  message?: string;
};

export enum TemplateCreateFields {
  name = 'name',
  message = 'message',
}

export enum ManageTableFields {
  messageTitle = 'messageTitle',
  status = 'status',
}

export const ManageTableTitles = {
  messageTitle: 'Message Title',
  status: 'Status',
};

export type ManageTableDataT = {
  key?: number;
  messageTitle?: string;
  status?: {id?: string};
};

export type LocationOptions = {
  id: string;
  city: string;
  venueName: string;
};

export type getListLocationOptionsRes = LocationOptions[];
