import React from 'react';
import {MenuMore, MenuMoreProps} from '../../Dashboard/ui/desktop/Table';

export type StatusItemProps = MenuMoreProps & {
  addRow?: boolean;
  edit?: boolean;
  id?: string;
  focusedId?: string;
};

export const StatusItem: React.FC<StatusItemProps> = ({...props}) => {
  return <MenuMore {...props} />;
};
