import React from 'react';
import {TooltipWrapper, CheckboxLabelWrapper, StyledSpan} from './styles';
import {palette} from '../theme/palette';
import {Tooltip} from '../Tooltip';
import {Typography} from 'antd';

const {Text} = Typography;

export type ItemLabelProps = {
  label: string;
  title?: string | React.ReactNode;
};

const toolTipStyle = {
  overlayStyle: {maxWidth: '300px'},
  overlayInnerStyle: {margin: '0 10px'},
};
console.log(toolTipStyle);
export const ItemLabel: React.FC<ItemLabelProps> = ({label, title}) => {
  return (
    <>
      <StyledSpan>{label}</StyledSpan>
      {title ? (
        <TooltipWrapper>
          <Tooltip
            overlayStyle={toolTipStyle.overlayStyle}
            overlayInnerStyle={toolTipStyle.overlayInnerStyle}
            title={<Text>{title}</Text>}
            color={palette.common.white}
          />
        </TooltipWrapper>
      ) : null}
    </>
  );
};

export const CheckboxLabel: React.FC<ItemLabelProps> = ({label, title}) => {
  return (
    <CheckboxLabelWrapper>
      <StyledSpan>{label}</StyledSpan>
      <TooltipWrapper>
        <Tooltip
          overlayStyle={toolTipStyle.overlayStyle}
          overlayInnerStyle={toolTipStyle.overlayInnerStyle}
          title={<Text>{title}</Text>}
          color={palette.common.white}
        />
      </TooltipWrapper>
    </CheckboxLabelWrapper>
  );
};
