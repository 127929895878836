import React from 'react';
import {useFetchSession, useLogOut} from '../hooks/auth';
import {WithChildren} from '../types/helpers';
import {PageLoader} from '../ui-kit/Loader';
import {getPermissions} from '../helpers/roles';

const CurrentUser: React.FC<WithChildren> = ({children}) => {
  const session = useFetchSession();
  const logout = useLogOut();
  const {isSuperAdmin} = getPermissions();

  if (!isSuperAdmin && !session?.loading) logout();
  if (session?.loading) return <PageLoader />;

  return children as React.ReactElement;
};

export default CurrentUser;
