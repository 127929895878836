import {DatePicker, Form, Select, SelectProps, Steps, Typography} from 'antd';
import styled from 'styled-components';
import {FormItem} from '../../../../ui-kit/Input';
import CheckIcon from '../../../../ui-kit/icons/check.svg';

const {Text} = Typography;

export const PageWrapper = styled.div`
  width: 100%;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  padding-top: 0;
`;

export const StepsCreate = styled(Steps)`
  border-bottom: 1px solid #becdcc;
  .ant-steps-item-container {
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ant-steps-item-title {
    font-size: 11px;
  }
  .ant-steps-item.ant-steps-item-active {
    &::before {
      display: none !important;
    }
  }
  .ant-steps-item {
    &::after {
      width: 8px;
      height: 8px;
      margin-top: -8px;
      margin-left: 4px;
    }
  }
`;

export const StyledSelect = styled(Select)<SelectProps>`
  &.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: ${({theme}) => theme.palette.common.brown};
  }
  &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: ${({theme}) => theme.palette.common.brown};
    box-shadow: none;
  }
  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 5px;
  }
  .ant-select-arrow {
    color: ${({theme}) => theme.palette.common.darkGray};
  }
  .ant-select-selection-item {
    display: flex;
    align-items: center;
  }
`;

export const CreateNewMessageCodeForm = styled(Form)`
  padding-left: 0;
  padding-right: 0;
`;

export const CodeFormItem = styled(FormItem)`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px !important;
  .ant-form-item-explain-error {
    color: ${({theme}) => theme.palette.common.redBrown};
    font-size: 10px;
    margin-top: 8px;
  }
  .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
    border: 2px solid ${({theme}) => theme.palette.common.redBrown};
  }
  .ant-form-item-required {
    width: 100%;
    height: auto;
  }
  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
  }
  .ant-col.ant-form-item-label {
    padding-bottom: 4px;
  }
  .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper {
    border: 2px solid ${({theme}) => theme.palette.common.redBrown};
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    min-height: 38px;
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    padding-top: 3px;
  }
`;

export const IconStepWrapper = styled.div`
  width: 22px;
  height: 22px;
  border-radius: 100%;
  border: 1px solid ${({theme}) => theme.palette.common.systemGray};
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const MessageFormWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  &.ant-picker {
    width: 100% !important;
  }
`;

export const DoubleSelectWrapper = styled(MessageFormWrapper)`
  gap: 25px;
`;

export const IconCurrent = styled.div<{$isCurrent: boolean}>`
  ${({$isCurrent}) => !$isCurrent && 'display: none'}
  width: 14px;
  height: 14px;
  border-radius: 100%;
  background-color: #1f4762;
`;

export const IconComplate = styled.img.attrs({
  src: CheckIcon,
})`
  width: 14px;
  height: 14px;
  border-radius: 100%;
`;

export const SettingsTitle = styled(Text)`
  font-weight: ${({theme}) => theme.font.weight.bold};
  font-size: 16px;
  line-height: 24px;
  display: block;
  margin-bottom: 8px;
`;

export const CustomDatePicker = styled(DatePicker)`
  &.ant-picker {
    width: 100%;
    height: 38px;
    border-radius: 5px;
  }
  &.ant-picker:hover,
  &.ant-picker-focused {
    border-color: ${({theme}) => theme.palette.common.brown};
    box-shadow: none;
  }
  &.ant-picker-today-btn {
    color: ${({theme}) => theme.palette.common.brown};
  }
`;

export const CreateNotifyCardWrapper = styled.div`
  min-height: calc(100vh - 120px);
  width: 100%;
  position: relative;
  padding: 16px;
`;

export const TicketTypeTip = styled.span`
  display: block;
  width: 100%;
  text-align: end;
  font-size: 10px;
  cursor: pointer;
  text-decoration: underline;
  margin-top: -4px;
`;

export const NextButton = styled.div<{$isAbsolute: boolean; $disabled?: boolean}>`
  width: calc(100vw - 32px);
  height: 40px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  background-color: #937e6b;
  color: white;
  justify-content: center;
  position: ${({$isAbsolute}) => ($isAbsolute ? 'absolute' : 'relative')};
  box-sizing: border-box;
  ${({$isAbsolute}) => $isAbsolute && 'bottom: 20px'};
  text-transform: uppercase;
  margin-bottom: 20px;
  margin-top: 20px;
  transition: background-color linear 0.2s;
  :hover,
  :active {
    background-color: #7d6855;
    ${({$disabled}) => $disabled && 'background-color: #E5E5E5'};
  }
  ${({$disabled}) => $disabled && 'background-color: #E5E5E5'};
`;

export const StyledSpan = styled.span`
  font-size: 12px;
`;

export const TextLimitTip = styled(StyledSpan)<{len: number; max: number}>`
  display: inline-block;
  width: 100%;
  text-align: end;
  ${({len, max}) => len > max && 'color: red'};
`;

export const HideWrapper = styled.div<{$isHide: boolean}>`
  display: ${({$isHide}) => ($isHide ? 'none' : 'unset')};
`;

export const PreviewWrapper = styled.div`
  padding: 16px;
  padding-bottom: 20px;
  background-color: #f5f5f6;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const PreviewContentBlock = styled.div`
  padding: 16px;
  border: 1px solid #becdcc;
  box-sizing: border-box;
  background-color: #fff;
  display: flex;
  flex-direction: column;
`;

export const OptionRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 22px;
  & > span:last-of-type {
    text-transform: capitalize;
    text-align: right;
    max-width: 339px;
  }
`;

export const OptionRowTitle = styled.span`
  font-size: 16px;
  line-height: 19px;
  color: #495258;
`;

export const OptionRowValue = styled.span<{$isError?: boolean}>`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: ${({$isError}) => ($isError ? '#fe2c0c' : '#495258')};
  position: relative;
`;

export const ErrorLabel = styled.label`
  font-size: 10px;
  font-weight: 400;
  color: #fe2c0c;
  position: absolute;
  right: 0;
  bottom: -16px;
`;

export const SubjectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 8px;
  gap: 8px;
`;

export const MessageWrapper = styled.div`
  padding-bottom: 8px;
`;

export const RewiewTitle = styled(Text)`
  font-size: 16px;
  font-weight: 600;
  display: block;
  color: ${({theme}) => theme.palette.common.darkGray};
`;

export const RewiewSubtitle = styled(Text)`
  font-size: 14px;
  font-weight: 400;
  display: block;
  color: ${({theme}) => theme.palette.common.darkGray};
`;

export const SectionTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

export const SectionTitleText = styled(Text)`
  font-size: 20px;
  font-weight: 700;
  text-transform: capitalize;
  color: #000;
`;

export const PreviewSendWrapper = styled.div`
  width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  padding: 12px 30px;
`;
