import styled from 'styled-components';

export const PreviewModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 31px;
  padding-right: 31px;
  margin-top: -6px;
  max-height: 640px;
  overflow: scroll;
  &::-webkit-scrollbar-thumb {
    background: #999;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar {
    width: 5px;
  }
`;

export const PreviewModalTitle = styled.span`
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  padding-bottom: 8px;
`;

export const TitleDescription = styled.span`
  font-size: 14px;
  line-height: 20px;
  padding-bottom: 22px;
  color: #495258;
`;

export const PreviewContentBlock = styled.div`
  padding: 22px 0 0 0;
  border-top: 1px solid #cccfd0;
  display: flex;
  flex-direction: column;
`;

export const OptionRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 22px;
  & > span:last-of-type {
    text-transform: capitalize;
    text-align: right;
    max-width: 339px;
  }
`;

export const SecondaryRow = styled(OptionRow)`
  justify-content: flex-start;
`;

export const OptionRowTitle = styled.span`
  font-size: 16px;
  line-height: 19px;
  color: #495258;
`;

export const OptionRowValue = styled.span<{$isError?: boolean}>`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: ${({$isError}) => ($isError ? '#fe2c0c' : '#495258')};
  position: relative;
`;

export const ErrorLabel = styled.label`
  font-size: 10px;
  font-weight: 400;
  color: #fe2c0c;
  position: absolute;
  right: 0;
  bottom: -16px;
`;

export const MessageWrapper = styled.div`
  margin-top: 12px;
  padding-bottom: 8px;
`;

export const SubjectWrapper = styled.div`
  padding-bottom: 8px;
`;
