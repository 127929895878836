import {WarningOutlined} from '@ant-design/icons';
import {Modal} from 'antd';
import React, {useEffect, useState} from 'react';
import {ManageTemplatesProps} from '.';
import {TableColumnT} from '../../../helpers/table';
import {ManageTableFields, ManageTableTitles} from '../../../types/messages';
import {ModalLvl} from '../../../types/settings';
import {TableWrapper} from '../../../ui-kit/Table';
import {ManageTemplateTable, ModalDescr} from './styles';
import {StatusItem, StatusItemProps} from './TableItems';

export const ManageTemplatesTable: React.FC<Omit<ManageTemplatesProps, 'formData'>> = ({
  templates,
  onDelete,
  goEdit,
}) => {
  const [focusedId, setFocusedId] = useState<string | undefined>('');
  const [delModalLvl, setDelModalLvl] = useState<ModalLvl>(ModalLvl.closed);
  const setDelPopup = (lvl: ModalLvl) => setDelModalLvl(lvl);
  const setFocusId = (id?: string) => setFocusedId(id);
  const setEditing = (id?: string) => id && goEdit(id);

  useEffect(() => {
    if (delModalLvl === ModalLvl.confirm) {
      Modal.confirm({
        title: `Are you sure you want to delete this message template?`,
        content: <ModalDescr>You cannot undo this action.</ModalDescr>,
        okText: 'Yes',
        cancelText: 'No',
        autoFocusButton: null,
        icon: <WarningOutlined />,
        onOk: () => {
          focusedId && onDelete(focusedId), setDelPopup(ModalLvl.success);
        },
        onCancel: () => setDelPopup(ModalLvl.closed),
      });
    }
    if (delModalLvl === ModalLvl.success) {
      Modal.success({
        title: `Message Template have been deleted successfully!`,
        content: <ModalDescr>Message Template have been deleted successfully!</ModalDescr>,
        okText: 'Done',
        onOk: () => {
          console.log('delete', focusedId);
          setDelPopup(ModalLvl.closed);
        },
      });
    }
  }, [delModalLvl]);

  const columns: TableColumnT[] = [
    {
      key: ManageTableFields.messageTitle,
      title: ManageTableTitles.messageTitle,
      dataIndex: ManageTableFields.messageTitle,
    },
    {
      key: ManageTableFields.status,
      title: ManageTableTitles.status,
      dataIndex: ManageTableFields.status,
      render: (props: StatusItemProps) => (
        <>
          <StatusItem
            {...props}
            setEditing={setEditing}
            setDelPopup={setDelPopup}
            setFocusId={setFocusId}
            focusedId={focusedId}
            goEdit={goEdit}
          />
        </>
      ),
    },
  ];

  return (
    <TableWrapper>
      <ManageTemplateTable columns={columns} dataSource={templates} showHeader={true} pagination={false} />
    </TableWrapper>
  );
};
