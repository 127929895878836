import React from 'react';
import {havingTicketsOptions, ticketStatusOptions} from '../../../../../constants/dashboard';
import {LocationOptionsT, steps} from '../../../types';
import {
  CodeFormItem,
  CreateNotifyCardWrapper,
  CustomDatePicker,
  DoubleSelectWrapper,
  NextButton,
  SettingsTitle,
  StyledSelect,
  TicketTypeTip,
} from '../styles';
import {CheckboxLabel, ItemLabel} from '../../../../../ui-kit/Labels';
import {MessageCreateFields} from '../../../../../types/messages';
import {FormTips} from '../../../common';
import {SelectWithCheckbox} from '../../../../../ui-kit/Select';
import {StyledTimePicker} from '../../../../../ui-kit/TimePicker/styles';
import {CustomCheckbox} from '../../../../../ui-kit/Checkbox';

export const AudienceFields: React.FC<{
  isDisabled: boolean;
  dateWarning: boolean;
  isExclude: boolean;
  onGuestsHavingTicketsChange: (value: any) => void;
  toggleExclude: () => void;
  excludeOptions: typeof havingTicketsOptions;
  isNextDisabled: boolean;
  inputsFocusing: (e: any) => void;
  onChangeStep: (step: number) => () => void;
  locationOptions: LocationOptionsT;
}> = ({
  isDisabled,
  inputsFocusing,
  onChangeStep,
  isNextDisabled,
  onGuestsHavingTicketsChange,
  toggleExclude,
  dateWarning,
  isExclude,
  locationOptions,
  excludeOptions,
}) => {
  return (
    <CreateNotifyCardWrapper>
      <SettingsTitle>Audience Segment</SettingsTitle>
      <CodeFormItem
        label={<ItemLabel label="Location*" title={FormTips.guestLocation} />}
        rules={[{required: true, message: 'Guests in location is required'}]}
        name={[MessageCreateFields.guestsInLocation]}>
        <StyledSelect
          placeholder={'Choose Location'}
          options={locationOptions}
          disabled={isDisabled}
          onFocus={inputsFocusing}
          onBlur={inputsFocusing}
        />
      </CodeFormItem>
      <CodeFormItem
        label={<ItemLabel label="Ticket Types*" title={FormTips.guestTicket} />}
        name={[MessageCreateFields.guestsHavingTickets]}
        rules={[{required: true, message: 'Guests having tickets is required'}]}>
        <StyledSelect
          placeholder={'Choose Ticket Type'}
          onChange={onGuestsHavingTicketsChange}
          options={havingTicketsOptions}
          disabled={isDisabled}
          onFocus={inputsFocusing}
          onBlur={inputsFocusing}
        />
      </CodeFormItem>
      <TicketTypeTip onClick={toggleExclude}>Set ticket-types to exclude from notification</TicketTypeTip>
      {!!isExclude && (
        <CodeFormItem
          label={<ItemLabel label="Exclude Ticket Types*" title={FormTips.guestTicketExclude} />}
          name={[MessageCreateFields.excludeGuestsHavingTickets]}
          rules={[{required: true, message: 'Guests having tickets is required'}]}>
          <SelectWithCheckbox
            options={excludeOptions}
            placeholder="Choose Ticket Type To Exclude"
            disabled={!excludeOptions}
          />
        </CodeFormItem>
      )}
      <CodeFormItem
        label={<ItemLabel label="Ticket Status*" title={FormTips.ticketStatus} />}
        name={[MessageCreateFields.orderStatus]}
        rules={[{required: true, message: 'Ticket Status is required'}]}>
        <StyledSelect
          placeholder={'Choose type'}
          options={ticketStatusOptions}
          disabled={isDisabled}
          onFocus={inputsFocusing}
          onBlur={inputsFocusing}
        />
      </CodeFormItem>
      <DoubleSelectWrapper>
        <CodeFormItem
          label={<ItemLabel label="Start Date*" title={FormTips.visitingDateFrom} />}
          name={[MessageCreateFields.visitingFromDate]}
          rules={[{required: true, message: 'Visiting from date is required'}]}>
          <CustomDatePicker
            onFocus={inputsFocusing}
            onBlur={inputsFocusing}
            disabled={isDisabled}
            status={dateWarning ? 'error' : undefined}
          />
        </CodeFormItem>
        <CodeFormItem
          label={<ItemLabel label="Start Time*" title={FormTips.visitingDateFromTime} />}
          name={[MessageCreateFields.visitingFromTime]}
          rules={[{required: true, message: 'Visiting from time is required'}]}>
          <StyledTimePicker
            onFocus={inputsFocusing}
            onBlur={inputsFocusing}
            use12Hours
            format="h:mm a"
            placeholder="Choose a time"
            disabled={isDisabled}
          />
        </CodeFormItem>
      </DoubleSelectWrapper>
      <DoubleSelectWrapper>
        <CodeFormItem
          label={<ItemLabel label="End Date*" title={FormTips.visitingDateUntil} />}
          name={[MessageCreateFields.visitingUntilDate]}
          rules={[{required: true, message: 'Visiting until date is required'}]}>
          <CustomDatePicker
            onFocus={inputsFocusing}
            onBlur={inputsFocusing}
            disabled={isDisabled}
            status={dateWarning ? 'error' : undefined}
            onChange={inputsFocusing}
          />
        </CodeFormItem>
        <CodeFormItem
          label={<ItemLabel label="End Time*" title={FormTips.visitingDateUntilTime} />}
          name={[MessageCreateFields.visitingUntilTime]}
          rules={[{required: true, message: 'Visiting until time is required'}]}>
          <StyledTimePicker
            onFocus={inputsFocusing}
            onBlur={inputsFocusing}
            use12Hours
            format="h:mm a"
            placeholder="Choose a time"
            disabled={isDisabled}
            onChange={inputsFocusing}
          />
        </CodeFormItem>
      </DoubleSelectWrapper>
      <SettingsTitle>Audience Flags</SettingsTitle>
      <CodeFormItem name={[MessageCreateFields.allowDateChange]} valuePropName="checked">
        <CustomCheckbox disabled={isDisabled}>
          <CheckboxLabel label="Allow Date Change" title={FormTips.allowDateChange} />
        </CustomCheckbox>
      </CodeFormItem>
      <CodeFormItem name={[MessageCreateFields.allowRefund]} valuePropName="checked">
        <CustomCheckbox disabled={isDisabled}>
          <CheckboxLabel label="Allow Refund" title={FormTips.allowRefund} />
        </CustomCheckbox>
      </CodeFormItem>
      <NextButton
        $isAbsolute={false}
        $disabled={isNextDisabled}
        onClick={isNextDisabled ? undefined : onChangeStep(steps.message)}>
        next
      </NextButton>
    </CreateNotifyCardWrapper>
  );
};
