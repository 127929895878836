import styled from 'styled-components';
import {Typography, Form} from 'antd';
import {FormItem} from '../../../ui-kit/Input';
import {Button} from '../../../ui-kit/Button';
import {Media} from '../../../ui-kit/theme/breakpoints';
const {Text} = Typography;

export const PageWrapper = styled.div`
  width: 100%;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  padding: 64px 190px 32px 190px;
  ${Media.down.xxl} {
    padding: 54px 90px 32px 90px;
  }
`;

export const GroupTitle = styled(Text)`
  font-weight: ${({theme}) => theme.font.weight.bold};
  font-size: 20px;
  line-height: 28px;
  display: block;
  margin-bottom: 22px;
  margin-left: 22px;
`;

export const TitleLine = styled.div`
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ModalDescr = styled(Text)`
  font-size: 14px;
  line-height: 22px;
  display: block;
`;

export const CodeForm = styled(Form)`
  padding: 18px 27px 21px 27px;
  box-sizing: border-box;
  border: 1px solid ${({theme}) => theme.palette.background.darkGray};
  border-radius: 8px;
  background-color: ${({theme}) => theme.palette.background.white};
`;

export const CreateNewMessageCodeForm = styled(CodeForm)`
  padding-left: 0;
  padding-right: 0;
`;

export const SuccessLine = styled.div`
  padding: 10px 0px;
  text-align: center;
`;

export const SuccessText = styled(Text)`
  font-size: 14px;
  color: ${({theme}) => theme.palette.common.darkGreen};
`;

export const CodeFormItem = styled(FormItem)<{noMargin?: boolean}>`
  width: 100%;
  && {
    margin-bottom: ${({noMargin}) => (noMargin ? '10px' : '22px')};
  }
  .ant-form-item-explain-error {
    color: ${({theme}) => theme.palette.common.redBrown};
    font-size: 10px;
    margin-top: 8px;
  }
  .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
    border: 2px solid ${({theme}) => theme.palette.common.redBrown};
  }
  .ant-form-item-required {
    width: 100%;
  }
  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
  }
  .ant-col.ant-form-item-label {
    padding-bottom: 4px;
  }
  .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper {
    border: 2px solid ${({theme}) => theme.palette.common.redBrown};
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    min-height: 38px;
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    padding-top: 3px;
  }
`;

export const FormButton = styled(Button)`
  width: 222px;
  height: 43px;
  font-size: 16px;
  font-weight: ${({theme}) => theme.font.weight.mediumBold};
`;

export const MessageFormWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  &.ant-picker {
    width: 100% !important;
  }
`;

export const LeftSide = styled.div`
  padding-left: 27px;
  width: calc(50% - 24px);
`;

export const RightSide = styled.div`
  padding-right: 27px;
  width: calc(50% - 24px);
`;

export const SettingsTitle = styled(Text)`
  font-weight: ${({theme}) => theme.font.weight.bold};
  font-size: 16px;
  line-height: 24px;
  display: block;
  margin-bottom: 8px;
`;

export const ErrorLine = styled.div`
  padding: 10px 0px;
  color: ${({theme}) => theme.palette.common.red};
`;

export const ErrorText = styled(Text)`
  font-size: 14px;
  color: ${({theme}) => theme.palette.common.red};
`;
