import React, {useEffect} from 'react';
import {LOGIN_EXTERNAL_LINK} from '../constants/env';
import {urlWithParams} from '../helpers/Route';
import {Portals} from '../types/helpers';

export const RedirectToLogin: React.FC = () => {
  //redirect
  useEffect(() => {
    LOGIN_EXTERNAL_LINK && window.location.replace(urlWithParams(LOGIN_EXTERNAL_LINK, false, Portals.notifications));
  }, []);

  return null;
};
