import React from 'react';
import RoutesSwitch from './Routes';
import {Normalize} from 'styled-normalize';
import {ThemeProvider} from 'styled-components';
import {defaultTheme} from './ui-kit/theme/theme';
import 'antd/dist/antd.css';
import './ui-kit/Modal/styles.css';
import './ui-kit/TimePicker/styles.css';
import './ui-kit/Calendar/styles.css';
import {Layout} from './containers/Layout';
import RecoilProvider from './containers/RecoilProvider';
import CurrentUser from './containers/CurrentUser';

function App() {
  return (
    <RecoilProvider>
      <CurrentUser>
        <ThemeProvider theme={defaultTheme}>
          <Layout>
            <RoutesSwitch />
          </Layout>
          <Normalize />
        </ThemeProvider>
      </CurrentUser>
    </RecoilProvider>
  );
}

export default App;
