import {MessageCreateFields} from '../../../types/messages';

export const FormTips = {
  notificationName:
    'Set an internal notification name. Should include reason (i.e. weather cancellation, ops issue, etc.).',
  messageType: 'Select instant or schedule for later.',
  date: 'Date the notification goes out (yyyy-mm-dd).',
  time: 'Time the notification goes out (in event timezone, using 12h clock).',
  notification: 'Mode SMS, Email or both.',
  guestLocation: 'All Locations or city-specific (e.g. MKE, WDC).',
  ticketStatus: 'Filter by ticket status.',
  guestTicket:
    'Filter based on ticket-type (GA, Parking, etc.) Guests who ordered those tickets will receive the notification.',
  guestTicketExclude: 'Filter to exclude ticket-type (GA, Parking, etc.)',
  allowDateChange: 'This flags all orders in the notification as eligible for a FREE date change.',
  allowRefund: 'This flags all orders in the notification as eligible for a refund.',
  visitingDateFrom: 'Set the earliest arrival date (yyyy-mm-dd).',
  visitingDateFromTime:
    'Set the earliest arrival time for the guests you want to message (in event timezone, using 12h clock).',
  visitingDateUntil: 'Set the latest arrival date (yyyy-mm-dd).',
  visitingDateUntilTime:
    'Set the latest arrival time for the guests you want to message (in event timezone, 12h clock).',
  clientName: 'Suggested client name format: Company name (Enchant location). I.e. Hilton Hotel (Washington, DC)',
  clientEmail: 'The Portal will send the Enchant Magic Link to this email address.',
  template: 'Use a pre-populated template to insert a subject line and body text for the message.',
  subject: 'Enter a subject line for email.',
  body: 'Enter text (email, SMS, or both).',
  description:
    "Describe the conditions and restrictions of the codes. The content you enter here will be visible on both the Client Portal and the Guests' end.\n" +
    '\n' +
    'Example description:\n' +
    '- Complimentary 1 GA Ticket\n' +
    '- Applicable on any GA Ticket Types (Adult, Child etc.)\n' +
    '- Applicable on All Event Dates\n' +
    '- Applicable in St. Petersburg, FL only',
};

export const formTitles = {create: 'Create New Message', edit: 'Edit Message'};

export const getFormButtons = (isInstant: boolean) => {
  return {
    create: isInstant ? 'Review Message' : 'Schedule Message',
    edit: isInstant ? 'Review Message' : 'Reschedule Message',
  };
};

export const setValuesOnBlurName = [
  MessageCreateFields.visitingUntilTime,
  MessageCreateFields.visitingFromTime,
  MessageCreateFields.scheduledTime,
];
