import {useState, useEffect} from 'react';
import {templatesToTableState, toCreateTemplateData, toSelectOptions, toUpdateTemplateData} from '../helpers/templates';
import {createTemplate, deleteTemplate, getListTemplates, getTemplate, updateTemplate} from '../queries/notifications';
import {getListTemplatesRes, getTemplateRes} from '../queries/types';
import {SelectOptionT} from '../types/helpers';
import {TemplateCreateFormValues} from '../types/messages';
import {useGetDepartments} from './settings';

export type useCreateTemplateT = {
  onCreate?: (data: TemplateCreateFormValues) => Promise<boolean>;
  onUpdate?: (data: TemplateCreateFormValues) => Promise<boolean>;
  success?: {
    status?: 'completed' | 'failed';
    total?: number;
  };
  error?: string;
  resetResult?: () => void;
  options: {departments: SelectOptionT[]};
};

export const useGetListTemplatesTable = () => {
  const [loading, setLoading] = useState(false);
  const [templates, setTemplates] = useState<getListTemplatesRes>([]);
  const fetch = () => {
    setLoading(true);
    getListTemplates().then((res) => {
      setTemplates(res?.body as getListTemplatesRes);
      setLoading(false);
    });
  };
  useEffect(() => {
    fetch();
  }, []);

  return {templates: templatesToTableState(templates), refetch: fetch, loading};
};

export const useCreateTemplate = (onSuccess?: () => void): useCreateTemplateT => {
  const [success, setSuccess] = useState({});
  const [error, setError] = useState('');
  const resetResult = () => {
    setError('');
    setSuccess({});
  };
  const {departments} = useGetDepartments();
  const onCreate = async (data: TemplateCreateFormValues) => {
    const body = toCreateTemplateData(data);
    try {
      console.log(body);
      await createTemplate({body});
      onSuccess?.();
      setSuccess({status: 'completed', total: 1});
      return true;
    } catch (e) {
      setError('Create Error!');
      console.log(e);
      return false;
    }
  };

  return {onCreate, success, error, resetResult, options: {departments: toSelectOptions(departments)}};
};

export const useGetTemplate = (id?: string, skip?: boolean) => {
  const [template, setTemplate] = useState<getTemplateRes>();
  const [loading, setLoading] = useState(false);

  const fetch = async () => {
    if (!id || skip) return;
    setLoading(true);
    await getTemplate({templateId: id})
      .then((res) => {
        setTemplate(res?.body as getTemplateRes);
        setLoading(false);
      })
      .catch(() => setLoading(false));
    setLoading(false);
  };

  const get = async (id?: string) => {
    if (!id) return;
    await getTemplate({templateId: id})
      .then((res) => {
        setTemplate(res?.body as getTemplateRes);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  useEffect(() => {
    fetch();
  }, [id, skip]);

  return {template, loading, refetch: fetch, getOne: get};
};

export const useUpdateTemplate = (id?: string, onSuccess?: () => void): useCreateTemplateT => {
  const [success, setSuccess] = useState({});
  const [error, setError] = useState('');
  const resetResult = () => {
    setError('');
    setSuccess({});
  };
  const {departments} = useGetDepartments();
  const onUpdate = async (data: TemplateCreateFormValues) => {
    try {
      if (!id) return false;
      const bodyFields = toUpdateTemplateData(data);
      await updateTemplate({templateId: id, body: bodyFields});
      onSuccess?.();
      setSuccess({status: 'completed'});
      return true;
    } catch (e) {
      setError('Update Error!');
      setSuccess({});
      console.log(e);
      return false;
    }
  };

  return {onCreate: onUpdate, success, error, resetResult, options: {departments: toSelectOptions(departments)}};
};

export const useDeleteTemplate = (onSuccess?: () => void) => {
  const onDelete = async (templateId: string) => {
    try {
      const res = await deleteTemplate({templateId});
      if (res) onSuccess?.();
    } catch (e) {}
  };
  return {onDelete};
};
