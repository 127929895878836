import React from 'react';
import {
  CodeFormItem,
  CreateNotifyCardWrapper,
  NextButton,
  SettingsTitle,
  StyledSelect,
  StyledSpan,
  TextLimitTip,
} from '../styles';
import {ItemLabel} from '../../../../../ui-kit/Labels';
import {MessageCreateFields} from '../../../../../types/messages';
import {FormTips} from '../../../common';
import Input, {TextArea} from '../../../../../ui-kit/Input';
import {InstructionForVariables} from '../../../../../ui-kit/InstructionForVariables';
import {TemplateOptionsT, TextLImitTypeT, steps} from '../../../types';
import {defaultSubject} from '../../../../../constants/dashboard';

export const MessageFields: React.FC<{
  templateOptions: TemplateOptionsT;
  onTemplateNameChange: (value: any) => void;
  isDisabled: boolean;
  isEmailMode: boolean;
  text: TextLImitTypeT;
  onSelectDynamicTemplate: (value: string) => () => void;
  inputsFocusing: (e: any) => void;
  isNextDisabled: boolean;
  onChangeStep: (step: number) => () => void;
  goDashboard: () => void;
}> = ({
  inputsFocusing,
  isDisabled,
  isEmailMode,
  onTemplateNameChange,
  templateOptions,
  text,
  onSelectDynamicTemplate,
  isNextDisabled,
  onChangeStep,
  goDashboard,
}) => {
  return (
    <CreateNotifyCardWrapper>
      <SettingsTitle>Message</SettingsTitle>
      <CodeFormItem
        label={<ItemLabel label="Message Template*" title={FormTips.template} />}
        name={[MessageCreateFields.eventTemplateName]}
        rules={[{message: 'Message Template is required'}]}>
        <StyledSelect
          placeholder={'Select Message Template'}
          options={templateOptions}
          onChange={onTemplateNameChange}
          disabled={isDisabled}
        />
      </CodeFormItem>
      <CodeFormItem
        label={<ItemLabel label="Message Subject" title={FormTips.subject} />}
        name={[MessageCreateFields.subject]}
        initialValue={defaultSubject}>
        <Input placeholder={'Write the message subject line'} disabled={isDisabled || !isEmailMode} />
      </CodeFormItem>
      <CodeFormItem
        label={<ItemLabel label="Message Body*" title={FormTips.body} />}
        name={[MessageCreateFields.message]}
        rules={[{message: 'Message Body is required', max: text.limit, type: 'string'}]}>
        {text.limit === 1000 && <StyledSpan key="note">SMS length must be 160 or fewer characters</StyledSpan>}
        <TextArea
          value={text.content || ''}
          rows={8}
          id={MessageCreateFields.message}
          maxLength={text.limit}
          placeholder={'Describe message details'}
          disabled={isDisabled}
          onChange={inputsFocusing}
        />
        <TextLimitTip key="tip" len={text.content.length || 0} max={text.limit}>{`${text.content.length || 0}/${
          text.limit
        }`}</TextLimitTip>
      </CodeFormItem>
      <SettingsTitle>Instructions on adding dynamic links:</SettingsTitle>
      <InstructionForVariables onSelect={onSelectDynamicTemplate} />
      <div onClick={isDisabled ? goDashboard : undefined}>
        <NextButton
          $isAbsolute={false}
          $disabled={isNextDisabled}
          onClick={isNextDisabled ? undefined : onChangeStep(steps.review)}>
          {isDisabled ? 'go dashboard' : 'review'}
        </NextButton>
      </div>
    </CreateNotifyCardWrapper>
  );
};
