import React, {useEffect, useState} from 'react';
import {PageWrapper, CreateNewMessageCodeForm, HideWrapper} from './styles';
import {LocationOptionsT, MessageFormProps, TextLImitTypeT, steps} from '../../types';
import {} from '../../../../ui-kit/Labels';
import {MessageCreateFields} from '../../../../types/messages';
import {setValuesOnBlurName} from '../../common';
import {Loader} from '../../../../ui-kit/Loader';
import {havingTicketsOptions, messageTypes, ticketStatusOptions} from '../../../../constants/dashboard';
import {Form as FormAntd} from 'antd';
import moment from 'moment';

import {LoadingWrapper} from '../desktop/styles';
import {StepsStatusBar} from './StepsStatusBar';
import {NotificationFields} from './steps/NotificationFields';
import {AudienceFields} from './steps/AudienceFields';
import {MessageFields} from './steps/MessageFields';
import {Review} from './steps/Review';

export const CreateMessageMobile: React.FC<MessageFormProps> = ({
  mode,
  formData,
  initial,
  loading,
  getTemplate,
  selectedTemplate,
  templates,
  goDashboard,
}) => {
  const {onFindAudience, resetResult, sellerOptions, guests, numTickets, loading: loadingAudience, onCreate} = formData;
  const isDisabled = initial?.notificationType === messageTypes.instant;
  const [step, setStep] = useState<number>(0);
  const [form] = FormAntd.useForm();
  const [flags, setFlags] = useState<{
    isEmailMode: boolean;
    isExclude: boolean;
    isInstant: boolean;
    isNextDisabled: boolean;
    dateWarning: boolean;
  }>({
    isEmailMode: initial?.notificationMode?.includes('email') ? true : false,
    isExclude: initial?.eventTemplateName?.exclude.length ? true : false,
    isInstant: false,
    isNextDisabled: mode === 'create',
    dateWarning: false,
  });
  const [text, setText] = useState<TextLImitTypeT>({content: '', limit: 1000});
  const [excludeOptions, setExcludeOptions] = useState<typeof havingTicketsOptions>([]);

  const templateOptions = templates.map((el) => ({label: el.messageTitle, value: `${el.messageTitle}`}));

  const locationOptions: LocationOptionsT = sellerOptions?.map((option) => ({
    id: option.id,
    label: `${option.city} (${option.venueName})`,
    value: option.id,
  }));

  const handleCreate = async () => {
    const values = form.getFieldsValue();
    const res = await onCreate?.({...values});
    if (res) goDashboard();
  };

  const onChangeStep = (step: number) => async () => {
    console.log(new Date().getTime());
    if (step === steps.review) {
      const values = form.getFieldsValue();
      if (values.visitingFromDate > values.visitingUntilDate) {
        setFlags((prev) => ({...prev, dateWarning: true}));
        setStep(steps.audience);
        checkFields(steps.audience);
        return;
      }
      setFlags((prev) => ({...prev, dateWarning: false}));
      await onFindAudience?.({...values});
    }
    setStep(step);
    checkFields(step);
  };

  const onSelectChange = (v: any) => setFlags((prev) => ({...prev, isInstant: v === 'instant' ? true : false}));

  const checkFields = (initStep?: number) => {
    const localStep = typeof initStep !== 'undefined' ? initStep : step;
    const values = form.getFieldsValue();

    const checkValues = (toCheck: string[]) => {
      return toCheck.every((item) => {
        if (Array.isArray(values[item])) {
          return Boolean(values[item].length);
        }
        return !!values[item];
      });
    };

    const checkNotification = () => {
      const toCheck = [
        MessageCreateFields.name,
        MessageCreateFields.notificationType,
        ...(values[MessageCreateFields.notificationType] === messageTypes.instant
          ? []
          : [MessageCreateFields.scheduledDate, MessageCreateFields.scheduledTime]),
        MessageCreateFields.notificationMode,
      ];
      return checkValues(toCheck);
    };

    const checkAudiecnde = () => {
      const toCheck = [
        MessageCreateFields.guestsInLocation,
        MessageCreateFields.guestsHavingTickets,
        MessageCreateFields.orderStatus,
        MessageCreateFields.visitingFromDate,
        MessageCreateFields.visitingFromTime,
        MessageCreateFields.visitingUntilDate,
        MessageCreateFields.visitingUntilTime,
        ...(flags.isExclude ? [MessageCreateFields.excludeGuestsHavingTickets] : []),
      ];
      return checkValues(toCheck);
    };

    const checkMessage = () => {
      const toCheck = [MessageCreateFields.message, ...(flags.isEmailMode ? [MessageCreateFields.subject] : [])];
      return checkValues(toCheck);
    };

    if (localStep === steps.notification) {
      setFlags((prev) => ({...prev, isNextDisabled: !checkNotification()}));
    }
    if (localStep === steps.audience) {
      setFlags((prev) => ({...prev, isNextDisabled: !checkAudiecnde()}));
    }
    if (localStep === steps.message) {
      setFlags((prev) => ({...prev, isNextDisabled: !checkMessage()}));
    }
  };

  const handleChangeForm = () => {
    resetResult?.();
    checkFields();
  };

  const onSelectDynamicTemplate = (value: string) => () => {
    const current = form.getFieldValue(MessageCreateFields.message);
    setText((prev) => ({...prev, content: current + value}));
    form.setFieldsValue({[MessageCreateFields.message]: current + value});
  };

  const onGuestsHavingTicketsChange = (value: any) => {
    const id = templates?.find((t) => t?.messageTitle === value)?.key;
    getTemplate?.(String(id || ''));
    form.setFieldsValue({[MessageCreateFields.excludeGuestsHavingTickets]: []});
    setExcludeOptions(havingTicketsOptions.filter((item) => item.value !== value));
  };

  const toggleExclude = () => {
    if (!form.getFieldValue(MessageCreateFields.guestsHavingTickets)) return;
    form.setFieldsValue({[MessageCreateFields.excludeGuestsHavingTickets]: []});
    setFlags((prev) => ({...prev, isExclude: !prev.isExclude}));
  };

  const onTemplateNameChange = (value: any) => {
    const id = templates?.find((t) => t?.messageTitle === value)?.key;
    getTemplate?.(String(id || ''));
  };

  const onChangeNotifMode = (value: string[]) => {
    setText((prev) => (value.includes('sms') ? {...prev, limit: 1000} : {...prev, limit: 1000}));
    setFlags((prev) => ({...prev, isEmailMode: value.includes('email') ? true : false}));
    form.setFieldsValue({[MessageCreateFields.notificationMode]: value});
  };

  const inputsFocusing = (e: any) => {
    if (e.type === 'blur' && e.target.value && e.target.id && setValuesOnBlurName.includes(e.target.id)) {
      form.setFieldsValue({[e.target.id]: moment.utc(e.target.value, 'h:mm a')});
    }
    if (e?.target?.id === MessageCreateFields.message) {
      setText((prev) => ({...prev, content: e?.target?.value || ''}));
      form.setFieldsValue({[MessageCreateFields.message]: e?.target?.value});
    }
    handleChangeForm();
  };

  useEffect(() => {
    if (selectedTemplate?.id) {
      setText((prev) => ({...prev, content: selectedTemplate?.message || ''}));
      form.setFieldsValue({[MessageCreateFields.message]: selectedTemplate?.message});
    }
    checkFields();
  }, [selectedTemplate?.id]);

  useEffect(() => {
    if (initial) {
      initial?.notificationMode && onChangeNotifMode(initial?.notificationMode);
      if (initial?.notificationType === messageTypes.instant) {
        setFlags((prev) => ({...prev, isInstant: true}));
      }
      if (initial?.eventTemplateName?.exclude.length) {
        setFlags((prev) => ({...prev, isExclude: true}));
      }
      if (initial?.message?.length) {
        setText((prev) => ({...prev, content: initial?.message || ''}));
      }
      form.setFieldsValue({[MessageCreateFields.name]: initial?.name});
      form.setFieldsValue({[MessageCreateFields.notificationType]: initial?.notificationType});
      form.setFieldsValue({
        [MessageCreateFields.scheduledDate]: initial?.scheduledTime ? moment.utc(initial?.scheduledTime) : undefined,
      });
      form.setFieldsValue({
        [MessageCreateFields.scheduledTime]: initial?.scheduledTime ? moment.utc(initial?.scheduledTime) : undefined,
      });
      form.setFieldsValue({[MessageCreateFields.guestsInLocation]: initial?.eventSellerId});
      form.setFieldsValue({[MessageCreateFields.guestsHavingTickets]: initial?.eventTemplateName?.include});
      form.setFieldsValue({[MessageCreateFields.excludeGuestsHavingTickets]: initial?.eventTemplateName?.exclude});
      form.setFieldsValue({
        [MessageCreateFields.orderStatus]: mode === 'create' ? ticketStatusOptions[0].value : initial?.orderStatus,
      });
      form.setFieldsValue({
        [MessageCreateFields.visitingFromDate]: initial?.eventStartDate
          ? moment.utc(initial?.eventStartDate)
          : undefined,
      });
      form.setFieldsValue({
        [MessageCreateFields.visitingFromTime]: initial?.eventStartDate
          ? moment.utc(initial?.eventStartDate)
          : undefined,
      });
      form.setFieldsValue({
        [MessageCreateFields.visitingUntilDate]: initial?.eventEndDate ? moment.utc(initial?.eventEndDate) : undefined,
      });
      form.setFieldsValue({
        [MessageCreateFields.visitingUntilTime]: initial?.eventEndDate ? moment.utc(initial?.eventEndDate) : undefined,
      });
      form.setFieldsValue({[MessageCreateFields.allowDateChange]: initial?.allowDateChange});
      form.setFieldsValue({[MessageCreateFields.allowRefund]: initial?.allowRefund});
      form.setFieldsValue({[MessageCreateFields.message]: initial?.message});
      form.setFieldsValue({[MessageCreateFields.subject]: initial?.subject});
    }
    checkFields();
  }, [JSON.stringify(initial)]);

  return (
    <>
      <StepsStatusBar step={step} onChangeStep={onChangeStep} />
      <PageWrapper>
        {(loading || loadingAudience) && (
          <LoadingWrapper>
            <Loader />
          </LoadingWrapper>
        )}
        <CreateNewMessageCodeForm layout={'horizontal'} form={form} onFieldsChange={handleChangeForm}>
          <HideWrapper $isHide={step !== steps.notification}>
            <NotificationFields
              isDisabled={isDisabled}
              inputsFocusing={inputsFocusing}
              onSelectChange={onSelectChange}
              isInstant={flags.isInstant}
              isNextDisabled={flags.isNextDisabled}
              onChangeNotifMode={onChangeNotifMode}
              onChangeStep={onChangeStep}
            />
          </HideWrapper>
          <HideWrapper $isHide={step !== steps.audience}>
            <AudienceFields
              excludeOptions={excludeOptions}
              inputsFocusing={inputsFocusing}
              isExclude={flags.isExclude}
              toggleExclude={toggleExclude}
              onChangeStep={onChangeStep}
              onGuestsHavingTicketsChange={onGuestsHavingTicketsChange}
              isDisabled={isDisabled}
              dateWarning={flags.dateWarning}
              isNextDisabled={flags.isNextDisabled}
              locationOptions={locationOptions}
            />
          </HideWrapper>
          <HideWrapper $isHide={step !== steps.message}>
            <MessageFields
              isNextDisabled={flags.isNextDisabled}
              onChangeStep={onChangeStep}
              inputsFocusing={inputsFocusing}
              isDisabled={isDisabled}
              isEmailMode={flags.isEmailMode}
              goDashboard={goDashboard}
              onSelectDynamicTemplate={onSelectDynamicTemplate}
              onTemplateNameChange={onTemplateNameChange}
              templateOptions={templateOptions}
              text={text}
            />
          </HideWrapper>
          <HideWrapper $isHide={step !== steps.review}>
            <Review
              guests={guests}
              locations={locationOptions}
              numTickets={numTickets}
              form={form}
              onCreate={handleCreate}
            />
          </HideWrapper>
        </CreateNewMessageCodeForm>
      </PageWrapper>
    </>
  );
};
