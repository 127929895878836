import React from 'react';
import {
  CodeFormItem,
  CreateNotifyCardWrapper,
  CustomDatePicker,
  DoubleSelectWrapper,
  NextButton,
  SettingsTitle,
  StyledSelect,
} from '../styles';
import {ItemLabel} from '../../../../../ui-kit/Labels';
import {MessageCreateFields} from '../../../../../types/messages';
import {Input} from '../../../../../ui-kit/Input/styles';
import {messageTypeOptions, notificationModeOptions} from '../../../../../constants/dashboard';
import {FormTips} from '../../../common';
import {StyledTimePicker} from '../../../../../ui-kit/TimePicker/styles';
import {SelectWithCheckbox} from '../../../../../ui-kit/Select';
import {steps} from '../../../types';

export const NotificationFields: React.FC<{
  isDisabled: boolean;
  isNextDisabled: boolean;
  onSelectChange: (v: any) => void;
  inputsFocusing: (e: any) => void;
  onChangeNotifMode: (value: string[]) => void;
  onChangeStep: (step: number) => () => void;
  isInstant: boolean;
}> = ({isDisabled, onSelectChange, inputsFocusing, isInstant, onChangeNotifMode, onChangeStep, isNextDisabled}) => {
  return (
    <CreateNotifyCardWrapper>
      <SettingsTitle>Schedule Settings</SettingsTitle>
      <CodeFormItem
        label={<ItemLabel label="Notification Name*" title={'12312412'} />}
        rules={[{required: true, message: 'Notification Name is required'}]}
        name={[MessageCreateFields.name]}>
        <Input placeholder="Enter name" disabled={isDisabled} />
      </CodeFormItem>
      <CodeFormItem
        label={<ItemLabel label="Delivery Type*" title={FormTips.messageType} />}
        name={[MessageCreateFields.notificationType]}
        rules={[{required: true, message: 'Message Type is required'}]}>
        <StyledSelect
          placeholder={'Choose type'}
          onChange={onSelectChange}
          options={messageTypeOptions}
          disabled={isDisabled}
          onFocus={inputsFocusing}
          onBlur={inputsFocusing}
        />
      </CodeFormItem>
      {!isInstant && (
        <DoubleSelectWrapper>
          <CodeFormItem
            label={<ItemLabel label="Send Date*" title={FormTips.date} />}
            name={[MessageCreateFields.scheduledDate]}
            rules={[{required: true, message: 'Date is required'}]}>
            <CustomDatePicker />
          </CodeFormItem>
          <CodeFormItem
            label={<ItemLabel label="Send Time*" title={FormTips.time} />}
            name={[MessageCreateFields.scheduledTime]}
            rules={[{required: true, message: 'Time is required'}]}>
            <StyledTimePicker
              onFocus={inputsFocusing}
              onBlur={inputsFocusing}
              use12Hours
              format="h:mm a"
              placeholder="Choose time"
            />
          </CodeFormItem>
        </DoubleSelectWrapper>
      )}
      <CodeFormItem
        label={<ItemLabel label="Notification Mode*" title={FormTips.notification} />}
        name={[MessageCreateFields.notificationMode]}
        required
        rules={[{required: true, message: 'Notification Mode is required'}]}>
        <SelectWithCheckbox
          onChange={onChangeNotifMode}
          options={notificationModeOptions}
          placeholder="Select Notification Mode"
          disabled={isDisabled}
        />
      </CodeFormItem>
      <NextButton
        $isAbsolute
        $disabled={isNextDisabled}
        onClick={isNextDisabled ? undefined : onChangeStep(steps.audience)}>
        next
      </NextButton>
    </CreateNotifyCardWrapper>
  );
};
