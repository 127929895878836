import React from 'react';
import {useNavigate} from 'react-router-dom';
import {DashboardPage} from '../../components/Dashboard/ui';
import {route} from '../../constants/routes';
import {useDeleteNotification, useGetListNotificationsTable} from '../../hooks/messages';

export const Dashboard = () => {
  const navigate = useNavigate();
  const {notifications, loading, refetch, isSearching, searchNotifications, pagination} =
    useGetListNotificationsTable();

  const goEdit = (id: string) => {
    navigate(route.editMessage.get({id}));
  };

  const {onDelete} = useDeleteNotification(refetch);

  return (
    <DashboardPage
      notifications={notifications}
      goEdit={goEdit}
      onDelete={onDelete}
      loading={loading}
      isSearching={isSearching}
      pagination={pagination}
      searchNotifications={searchNotifications}
    />
  );
};
