import React, {useEffect, useState} from 'react';
import {
  NotificationTable,
  LoaderWrapper,
  MenuAction,
  ModalDescr,
  SearchWrapper,
  SearchInput,
  EnterButton,
  SearchBtn,
  SearchResultWrapper,
  ResultLabel,
  ClearButton,
} from './styles';
import {TableWrapper} from '../../../../ui-kit/Table';
import {DashboardTableDataT} from '../../../../types/dashboard';
import {
  DashboardCodeMap,
  DashboardTableColumnsTitles,
  DashboardTableFields,
  TableColumnT,
  toTableDateFormat,
} from '../../../../helpers/table';
import {ButtonMore, InfoButton} from '../../../../ui-kit/Button';
import {Dropdown, Menu, Modal} from 'antd';
import {ItemType} from 'antd/es/menu/hooks/useItems';
import {CloseOutlined, SearchOutlined, WarningOutlined} from '@ant-design/icons';
import {Loader} from '../../../../ui-kit/Loader';
import {ModalLvl} from '../../../../types/settings';

type DashboardTableProps = {
  data?: DashboardTableDataT[];
  goEdit: (id: string) => void;
  onDelete: (id: string) => Promise<boolean>;
  searchNotifications: (v: string) => void;
  loading?: boolean;
};

export const DashboardTable: React.FC<DashboardTableProps> = ({
  data,
  goEdit,
  onDelete,
  loading,
  searchNotifications,
}) => {
  const [focusedId, setFocusedId] = useState<string | undefined>('');
  const [delModalLvl, setDelModalLvl] = useState<ModalLvl>(ModalLvl.closed);
  const setDelPopup = (lvl: ModalLvl) => setDelModalLvl(lvl);
  const setFocusId = (id?: string) => setFocusedId(id);
  const setEditing = (id?: string) => id && goEdit(id);
  const [searchValue, setSearchValue] = useState('');
  const [isSearchRes, setIsSearchRes] = useState<boolean>(false);

  useEffect(() => {
    if (delModalLvl === ModalLvl.confirm) {
      Modal.confirm({
        title: `Are you sure you want to delete this message?`,
        content: <ModalDescr>You cannot undo this action.</ModalDescr>,
        okText: 'Yes',
        cancelText: 'No',
        autoFocusButton: null,
        icon: <WarningOutlined />,
        onOk: async () => {
          if (focusedId) {
            const res = await onDelete(focusedId);
            if (res) return setDelPopup(ModalLvl.success);
          }
          return setDelPopup(ModalLvl.closed);
        },
        onCancel: () => setDelPopup(ModalLvl.closed),
      });
    }
    if (delModalLvl === ModalLvl.success) {
      Modal.success({
        title: `The message has been deleted!`,
        content: <ModalDescr>You will not see the message on the Portal now.</ModalDescr>,
        okText: 'Done',
        autoFocusButton: null,
        onOk: () => {
          setDelPopup(ModalLvl.closed);
        },
      });
    }
  }, [delModalLvl]);

  const columns: TableColumnT[] = [
    {
      key: DashboardTableFields.id,
      title: DashboardTableColumnsTitles.id,
      dataIndex: DashboardTableFields.id,
      // render: (id) => {
      //   return <Link to={'/'}>{id}</Link>;
      // },
    },
    {
      key: DashboardTableFields.name,
      title: DashboardTableColumnsTitles.name,
      dataIndex: DashboardTableFields.name,
      // render: (values: {id: string; name: string}) => {
      //   return <Link to={'/'}>{values.name}</Link>;
      // },
    },
    {
      key: DashboardTableFields.messageType,
      title: DashboardTableColumnsTitles.messageType,
      dataIndex: DashboardTableFields.messageType,
    },
    {
      key: DashboardTableFields.notificationType,
      title: DashboardTableColumnsTitles.notificationType,
      dataIndex: DashboardTableFields.notificationType,
    },
    {
      key: DashboardTableFields.location,
      title: DashboardTableColumnsTitles.location,
      dataIndex: DashboardTableFields.location,
    },
    {
      key: DashboardTableFields.dateSent,
      title: DashboardTableColumnsTitles.dateSent,
      dataIndex: DashboardTableFields.dateSent,
      render: (value: string) => {
        return <>{value && toTableDateFormat(value)}</>;
      },
    },
    {
      key: DashboardTableFields.scheduledOn,
      title: DashboardTableColumnsTitles.scheduledOn,
      dataIndex: DashboardTableFields.scheduledOn,
      render: (value: string) => {
        return <>{value && toTableDateFormat(value)}</>;
      },
    },
    {
      key: DashboardTableFields.status,
      title: DashboardTableColumnsTitles.status,
      dataIndex: DashboardTableFields.status,
      render: (status: DashboardTableDataT['status']) => {
        return <InfoButton variant={status && DashboardCodeMap[status]}>{status}</InfoButton>;
      },
    },
    {
      key: DashboardTableFields.actions,
      dataIndex: DashboardTableFields.actions,
      title: (
        <Search
          searchNotifications={searchNotifications}
          showResult={setIsSearchRes}
          value={searchValue}
          setValue={setSearchValue}
          disabled={false}
        />
      ),
      render: (props: DashboardTableDataT['actions']) => (
        <MenuMore
          id={props?.id}
          setEditing={setEditing}
          setDelPopup={setDelPopup}
          setFocusId={setFocusId}
          goEdit={goEdit}
        />
      ),
    },
  ];

  const locale = {
    emptyText: <ResultLabel>There are no results found for your search.</ResultLabel>,
  };

  return (
    <>
      {isSearchRes && searchValue && (
        <SearchResult
          resQty={data?.length}
          showResult={setIsSearchRes}
          searchNotifications={searchNotifications}
          value={searchValue}
          setValue={setSearchValue}
        />
      )}
      <Modal />
      <TableWrapper>
        <NotificationTable
          columns={columns}
          dataSource={data}
          pagination={false}
          locale={isSearchRes && !data?.length ? locale : undefined}
        />
        {loading && (
          <LoaderWrapper>
            <Loader />
          </LoaderWrapper>
        )}
      </TableWrapper>
    </>
  );
};

export type MenuMoreProps = {
  isEditing?: boolean;
  id?: string;
  setDelPopup?: (lvl: ModalLvl) => void;
  setFocusId?: (id?: string) => void;
  setEditing?: (id?: string) => void;
  goEdit?: (id: string) => void;
};

export const MenuMore: React.FC<MenuMoreProps> = ({id, setDelPopup, setEditing, setFocusId, goEdit}) => {
  const onEdit = () => {
    setFocusId?.(id);
    setEditing?.(id);
    setDelPopup?.(ModalLvl.closed);
    id && goEdit?.(id);
  };
  const onDelete = () => {
    setFocusId?.(id);
    setDelPopup?.(ModalLvl.confirm);
  };

  const ddActions: ItemType[] = [
    {key: 1, label: <MenuAction onClick={onEdit}>Edit</MenuAction>},
    {
      key: 2,
      label: (
        <MenuAction $danger onClick={onDelete}>
          Delete
        </MenuAction>
      ),
    },
  ];

  return (
    <Dropdown overlay={<Menu items={ddActions} />} trigger={['click']}>
      <ButtonMore />
    </Dropdown>
  );
};

type SearchProps = {
  value: string;
  setValue: (v: string) => void;
  searchNotifications: (v: string) => void;
  showResult: (v: boolean) => void;
  disabled?: boolean;
};

export const Search: React.FC<SearchProps> = ({value, setValue, searchNotifications, showResult, disabled}) => {
  const [open, setOpen] = useState(false);
  const handleOpenChange = (flag: boolean) => {
    setOpen(flag);
  };
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => setValue(e.target.value);
  const handleOnSearch = () => {
    searchNotifications(value);
    setOpen(false);
    showResult(true);
  };

  const searchInput = (
    <SearchWrapper>
      <SearchInput
        prefix={<SearchOutlined />}
        placeholder="Search by client name or contact person..."
        onChange={handleInputChange}
        onPressEnter={handleOnSearch}
        value={value}
      />
      <EnterButton onClick={handleOnSearch} disabled={value === ''}>
        Search
      </EnterButton>
    </SearchWrapper>
  );

  return (
    <SearchBtn disabled={disabled}>
      <Dropdown
        overlay={searchInput}
        trigger={['click']}
        placement="bottomRight"
        onVisibleChange={handleOpenChange}
        visible={open}>
        {open ? <CloseOutlined /> : <SearchOutlined />}
      </Dropdown>
    </SearchBtn>
  );
};
type SearchResultProps = SearchProps & {
  resQty?: number;
};

const SearchResult: React.FC<SearchResultProps> = ({resQty, showResult, value, setValue, searchNotifications}) => {
  const onClearClick = () => {
    setValue('');
    searchNotifications('');
    showResult(false);
  };

  return (
    <SearchResultWrapper>
      <ResultLabel>{`${resQty} Results for "${value}"`}</ResultLabel>
      <ClearButton onClick={onClearClick}>Clear Search</ClearButton>
    </SearchResultWrapper>
  );
};
