import React, {useEffect, useState} from 'react';
import {
  CardStatTitle,
  CardStatValue,
  CardStatWrapper,
  CreateButton,
  GroupCardStatWrapper,
  GroupStatWrapper,
  HeaderWrapper,
  InfiniteScrollListWrapper,
  ManageButton,
  NotificationCardWrapper,
  NotificationCount,
  NotificationDetailsItem,
  NotificationDetailsItemLabel,
  NotificationDetailsItemValue,
  NotificationDetailsLocationItem,
  NotificationDetailsLocationWrapper,
  NotificationDetailsText,
  NotificationTitle,
  NotificationWrapper,
  ScrollToText,
  ScrollToWrapper,
  StatsWrapper,
  TitleText,
  ModalDescr,
  ViewMoreStat,
  MenuMoreWrapper,
} from './styles';
import {DashboardCodeMap, DashboardPageProps} from '../../types';
import {route} from '../../../../constants/routes';
import {DashboardCardT} from '../../../../types/dashboard';
import {InfoButton} from '../../../../ui-kit/Button';
import arrowDown from '../../../../ui-kit/icons/arrow-down.svg';
import {toTableDateFormat} from '../../../../helpers/table';
import {WarningOutlined} from '@ant-design/icons';
import {ModalLvl} from '../../../../types/settings';
import {Modal} from 'antd';
import {MenuMore} from '../desktop/Table';

const CardsMock: DashboardCardT[] = [
  {
    label: 'SMS Groups',
    value: '10',
    isTitle: true,
  },
  {
    label: 'SMS Sent',
    value: '40,5677',
  },
  {
    label: 'Email Groups',
    value: '10',
    isTitle: true,
  },
  {
    label: 'Emails Sent',
    value: '40,5677',
  },
];

export const MobileDashborad: React.FC<DashboardPageProps> = (props) => {
  const scrollToTop = () => {
    window.scrollTo({top: document.body.scrollHeight, behavior: 'smooth'});
  };
  return (
    <>
      <TitleText>Notifications Portal</TitleText>
      <HeaderWrapper>
        <ManageButton to={route.manageTemplates.path}>Manage Templates</ManageButton>
        <CreateButton to={route.createNewMessage.path}>Create New Message</CreateButton>
      </HeaderWrapper>
      <Stats />
      <Notifications {...props} />
      {props?.pagination?.hasNextPage && (
        <ScrollToWrapper onClick={scrollToTop}>
          <ScrollToText>Scroll to view more</ScrollToText>
          <img src={arrowDown} />
        </ScrollToWrapper>
      )}
    </>
  );
};

const Notifications: React.FC<DashboardPageProps> = ({pagination, notifications, goEdit, onDelete}) => {
  const [focusedId, setFocusedId] = useState<string | undefined>('');
  const [delModalLvl, setDelModalLvl] = useState<ModalLvl>(ModalLvl.closed);

  const setDelPopup = (lvl: ModalLvl) => setDelModalLvl(lvl);

  const setFocusId = (id?: string) => setFocusedId(id);

  const setEditing = (id?: string) => id && goEdit(id);

  useEffect(() => {
    if (delModalLvl === ModalLvl.confirm) {
      Modal.confirm({
        title: `Are you sure you want to delete this message?`,
        content: <ModalDescr>You cannot undo this action.</ModalDescr>,
        okText: 'Yes',
        cancelText: 'No',
        autoFocusButton: null,
        icon: <WarningOutlined />,
        onOk: async () => {
          if (focusedId) {
            const res = await onDelete(focusedId);
            if (res) return setDelPopup(ModalLvl.success);
          }
          return setDelPopup(ModalLvl.closed);
        },
        onCancel: () => setDelPopup(ModalLvl.closed),
      });
    }
    if (delModalLvl === ModalLvl.success) {
      Modal.success({
        title: `The message has been deleted!`,
        content: <ModalDescr>You will not see the message on the Portal now.</ModalDescr>,
        okText: 'Done',
        autoFocusButton: null,
        onOk: () => {
          setDelPopup(ModalLvl.closed);
        },
      });
    }
  }, [delModalLvl]);

  const onFetch = () => {
    const page = pagination?.pageNumber;
    pagination?.changePage(page + 1);
  };
  return (
    <NotificationWrapper>
      <NotificationCount>{pagination?.total} Notifications</NotificationCount>
      <InfiniteScrollListWrapper
        loader={null}
        next={onFetch}
        hasMore={pagination?.hasNextPage}
        dataLength={notifications?.length}>
        {notifications?.map((item) => (
          <NotificationCardWrapper key={item?.id}>
            <InfoButton variant={item.status && DashboardCodeMap[item.status]}>{item?.status}</InfoButton>
            <NotificationTitle>
              {item?.id} - {item?.name} - {item?.location}
            </NotificationTitle>
            <NotificationTitle>{item?.notificationType}</NotificationTitle>
            <NotificationDetailsText>Details</NotificationDetailsText>
            <NotificationDetailsItem>
              <NotificationDetailsItemLabel>Message Type</NotificationDetailsItemLabel>
              <NotificationDetailsItemValue>{item?.status}</NotificationDetailsItemValue>
            </NotificationDetailsItem>
            <NotificationDetailsItem>
              <NotificationDetailsItemLabel>Date Sent</NotificationDetailsItemLabel>
              <NotificationDetailsItemValue>
                {item?.dateSent && toTableDateFormat(item?.dateSent)}
              </NotificationDetailsItemValue>
            </NotificationDetailsItem>
            <NotificationDetailsItem>
              <NotificationDetailsItemLabel>Event Start Time</NotificationDetailsItemLabel>
              <NotificationDetailsItemValue>
                {item?.scheduledOn && toTableDateFormat(item?.scheduledOn)}
              </NotificationDetailsItemValue>
            </NotificationDetailsItem>
            <NotificationDetailsLocationWrapper>
              {item?.eventSellerId?.map((location, index) => (
                <NotificationDetailsLocationItem key={index}>{location}</NotificationDetailsLocationItem>
              ))}
            </NotificationDetailsLocationWrapper>
            <MenuMoreWrapper>
              <MenuMore
                id={item?.id}
                setEditing={setEditing}
                setDelPopup={setDelPopup}
                setFocusId={setFocusId}
                goEdit={goEdit}
              />
            </MenuMoreWrapper>
          </NotificationCardWrapper>
        ))}
      </InfiniteScrollListWrapper>
    </NotificationWrapper>
  );
};

const Stats = () => {
  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => setShowMore((prev) => !prev);

  return (
    <StatsWrapper onClick={toggleShowMore}>
      <GroupStatWrapper $showMore={showMore}>
        {CardsMock?.filter((item) => (showMore ? item.label : item?.isTitle)).map((item) => (
          <CardStatWrapper key={item.label}>
            {item?.isTitle && showMore && <CardStatTitle>{item?.label?.split(' ')?.[0]?.toUpperCase()}</CardStatTitle>}
            <GroupCardStatWrapper $showMore={showMore}>
              <CardStatTitle $showMore={showMore}>{item.label}</CardStatTitle>
              <CardStatValue $showMore={showMore}>{item.value}</CardStatValue>
            </GroupCardStatWrapper>
          </CardStatWrapper>
        ))}
      </GroupStatWrapper>
      {!showMore && <ViewMoreStat>View All Stats</ViewMoreStat>}
    </StatsWrapper>
  );
};
