import {messageTypes} from '../constants/dashboard';
import {removeEmptyKeys} from '../hooks/common';
import {getListNotificationsRes, getListTemplatesRes, NotificationsT, templateBody} from '../queries/types';
import {DashboardTableDataT} from '../types/dashboard';
import {SelectOptionT} from '../types/helpers';
import {
  LocationOptions,
  ManageTableDataT,
  MessageTypes,
  TemplateCreateFields,
  TemplateCreateFormValues,
} from '../types/messages';

export const templatesToTableState = (values: getListTemplatesRes): ManageTableDataT[] => {
  return values.map((el) => ({
    key: el?.id,
    messageTitle: el?.name,
    status: {id: String(el?.id)},
  }));
};

export const notificationsToTableState = (
  values: getListNotificationsRes,
  options: LocationOptions[],
): DashboardTableDataT[] => {
  const valuesWithLocation = values?.map((v: NotificationsT) => {
    if ((v?.eventSellerId?.length || 0) > 1) return {...v, location: locationToAcronym(OptionValues.ALL)};
    const location = options.filter((option: LocationOptions) => option.id === v?.eventSellerId?.[0])[0]?.city;
    const acrLocation = locationToAcronym(location);
    return {...v, location: acrLocation};
  });

  return valuesWithLocation.map((el) => ({
    key: el?.id,
    id: String(el?.id),
    eventSellerId: el?.eventSellerId?.map((location) => {
      const locationCity = options.find((item) => item.id === location)?.city;
      return locationToAcronym(locationCity as string) || '';
    }),
    name: el?.name,
    messageType: el?.notificationType,
    notificationType: el?.notificationMode
      ?.map((item) => (item?.toLowerCase() === 'sms' ? item?.toUpperCase() : item))
      .join(','),
    location: el?.location,
    dateSent: el.notificationType === messageTypes.scheduled ? el?.scheduledTime : el?.createdAt,
    scheduledOn: el?.eventStartDate,
    status:
      el?.notificationType === MessageTypes.scheduled && el?.outboundStatus === MessageTypes.sent
        ? MessageTypes.sent
        : el?.notificationType,
    actions: {id: String(el?.id)},
  }));
};

export const toCreateTemplateData = (values: TemplateCreateFormValues): templateBody => {
  return {
    [TemplateCreateFields.name]: values?.name || '',
    [TemplateCreateFields.message]: values?.message || '',
  };
};

export const toUpdateTemplateData = (values: TemplateCreateFormValues): templateBody => {
  return removeEmptyKeys({
    [TemplateCreateFields.name]: values?.name || '',
    [TemplateCreateFields.message]: values?.message || '',
  }) as templateBody;
};

export const toInitialTemplateState = (values?: TemplateCreateFormValues): Partial<templateBody> => {
  return {
    [TemplateCreateFields.name]: values?.name,
    [TemplateCreateFields.message]: values?.message,
  };
};

export const toSelectOptions = (values: any[]): SelectOptionT[] => {
  return values.map((el) => ({label: el?.name, value: el?.id}));
};

export enum OptionValues {
  //DAL = 'Fair Park',
  // LRW = 'Resorts World Las Vegas',
  // NAS = 'First Horizon Park',
  // SAC = 'Sutter Health Park',
  KSC = 'Kansas City, KS',
  LVS = 'Las Vegas, NV',
  SJO = 'San Jose, CA',
  MKE = 'Milwaukee, WI',
  SCO = 'Scottsdale, AZ',
  STP = 'St. Petersburg, FL',
  WDC = 'Washington, DC',
  VAN = 'Vancouver, BC',
  ALL = 'All Locations',
}

export enum AcronymValues {
  //DAL = 'DAL',
  //LRW = 'LRW',
  //NAS = 'NAS',
  //SAC = 'SAC',
  KSC = 'KSC',
  LVS = 'LVS',
  SJO = 'SJO',
  MKE = 'MKE',
  SCO = 'SCO',
  STP = 'STP',
  WDC = 'WDC',
  VAN = 'VAN',
  ALL = 'ALL',
}

export const locationToAcronym = (value: string) => {
  switch (value) {
    case OptionValues.LVS:
      return AcronymValues.LVS;
    case OptionValues.SJO:
      return AcronymValues.SJO;
    case OptionValues.SCO:
      return AcronymValues.SCO;
    case OptionValues.STP:
      return AcronymValues.STP;
    case OptionValues.WDC:
      return AcronymValues.WDC;
    case OptionValues.ALL:
      return AcronymValues.ALL;
    case OptionValues.KSC:
      return AcronymValues.KSC;
    case OptionValues.MKE:
      return AcronymValues.MKE;
    case OptionValues.VAN:
      return AcronymValues.VAN;
    case OptionValues.ALL:
      return AcronymValues.ALL;
  }
};
