import {BaseObjT} from '../../types/helpers';

export enum CreateDepartmentField {
  name = 'name',
  description = 'description',
}
export enum CreateRoleField {
  name = 'name',
}

export enum CreatePermissionField {
  name = 'name',
  description = 'description',
}
// ------ PERMISSIONS --------
export type PermissionT = BaseObjT & {
  name: string;
  description: string;
};

export type getPermissionsRes = PermissionT[];

export type PermissionBody = {
  [CreatePermissionField.name]: string;
  [CreatePermissionField.description]: string;
};
export type createPermissionReq = {
  body: PermissionBody;
};

export type updatePermissionReq = {
  permissionId: string;
  body: PermissionBody;
};

export type deletePermissionReq = {
  permissionId: string;
};

// ------ ROLES --------
export type RolePermissionT = BaseObjT & {
  roleId: 1;
  permissionId: 1;
};

export type PermissionWithRoleT = BaseObjT & {
  name: string;
  description: string;
  RolePermission: RolePermissionT;
};

export type RoleT = BaseObjT & {
  name: string;
  description: string;
  permissions: PermissionWithRoleT[];
};

export type RoleBody = {
  [CreateRoleField.name]: string;
};
export type createRoleReq = {
  body: RoleBody;
};

export type updateRoleReq = {
  roleId: string;
  body: RoleBody;
};

export type deleteRoleReq = {
  roleId: string;
};

// ------ DEPARTMENT --------

export type DepartmentT = BaseObjT & {
  name: string;
  description: string;
};
export type DepartmentBody = {
  [CreateDepartmentField.name]: string;
  [CreateDepartmentField.description]: string;
};

export type getDepartmentsRes = DepartmentT[];

export type createDepartmentReq = {
  body: DepartmentBody;
};

export type updateDepartmentReq = {
  departmentId: string;
  body: DepartmentBody;
};

export type deleteDepartmentReq = {
  departmentId: string;
};

// ------ USER --------

export type CreateUserBodyT = {
  email: string;
  firstName: string;
  lastName: string;
  roles: string[];
  departments: string[];
  portals?: string[];
};
export type createUserReq = {
  body: CreateUserBodyT;
};
export type updateUserReq = {
  id: string;
  body: CreateUserBodyT;
};
