import React from 'react';
import {IconComplate, IconCurrent, IconStepWrapper, StepsCreate} from './styles';
import {steps} from '../../types';

export const StepsStatusBar: React.FC<{step: number; onChangeStep: (step: number) => () => void}> = ({
  step,
  onChangeStep,
}) => {
  return (
    <StepsCreate
      direction="horizontal"
      responsive={false}
      type="navigation"
      current={step}
      items={[
        {
          status: 'finish',
          title: 'Notifications',
          onClick: step > steps.notification ? onChangeStep(steps.notification) : undefined,
          icon: <IconStepComponent isCurrent={step === steps.notification} isComplate={step > steps.notification} />,
        },
        {
          status: 'finish',
          title: 'Audience',
          onClick: step > steps.audience ? onChangeStep(steps.audience) : undefined,
          icon: <IconStepComponent isCurrent={step === steps.audience} isComplate={step > steps.audience} />,
        },
        {
          status: 'finish',
          title: 'Message',
          onClick: step > steps.message ? onChangeStep(steps.message) : undefined,
          icon: <IconStepComponent isCurrent={step === steps.message} isComplate={step > steps.message} />,
        },
      ]}
    />
  );
};

const IconStepComponent: React.FC<{isCurrent: boolean; isComplate: boolean}> = ({isCurrent, isComplate}) => {
  return <IconStepWrapper>{isComplate ? <IconComplate /> : <IconCurrent $isCurrent={isCurrent} />}</IconStepWrapper>;
};
