import React from 'react';
import {InstructionsWrapper} from './styles';

export const InstructionForVariables: React.FC<{onSelect: (value: string) => () => void}> = ({onSelect}) => {
  return (
    <InstructionsWrapper>
      <div>You can use links in your message using these handlebars:</div>
      <br />
      <a onClick={onSelect('{{first_name}}')}>{'{{first_name}} First name of the customer'}</a>
      <br />
      <a onClick={onSelect('{{self_serve_link}}')}>{'{{self_serve_link}} Link to Access My Account'}</a>
      <br />
      <a onClick={onSelect('{{waivers_link}}')}>{'{{waivers_link}} Link to access Manage Waivers'}</a>
      <br />
      <a onClick={onSelect('{{ticket_link}}')}>{'{{ticket_link}} Link to open ticket'}</a>
    </InstructionsWrapper>
  );
};
