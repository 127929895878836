import React, {useEffect, useState} from 'react';
import {ManagementTableColumnsTitles, ManagementTableFields, TableColumnT} from '../../../helpers/table';
import {Table} from '../styles';
import {
  AccessItem,
  ActionsItem,
  ActionsItemProps,
  DepartmentItem,
  DepartmentItemProps,
  EmailItem,
  EmailItemProps,
  FullNameItem,
  FullNameItemProps,
  RoleItem,
  RoleItemProps,
  UsersFilter,
  UsersFilterProps,
} from './TableItems';
import {ManageTableItemT, ModalLvl} from '../../../types/settings';
import {Modal} from 'antd';
import {ModalDescr} from '../../Dashboard/ui/desktop/styles';
import {TableWrapper} from '../../../ui-kit/Table';
import {QuestionCircleOutlined} from '@ant-design/icons';
import {useCreatingUser, useEditingUser, useManageUserActionsT} from '../../../hooks/settings';

type ManagementTablePropsT = UsersFilterProps & {
  data: ManageTableItemT[];
  refetch: () => void;
  onDelete?: useManageUserActionsT['onDelete'];
};

export const ManagementTable: React.FC<ManagementTablePropsT> = ({
  data,
  refetch,
  roles,
  departments,
  onDelete,
  filterUsers,
}) => {
  const [focusedId, setFocusedId] = useState<string | undefined>('');
  const [isEditing, setIsEditing] = useState(false);
  const setFocusId = (id?: string) => setFocusedId(id);
  const setEditing = (b: boolean) => setIsEditing(b);
  const [delModalLvl, setDelModalLvl] = useState<ModalLvl>(ModalLvl.closed);
  const setDelPopup = (lvl: ModalLvl) => setDelModalLvl(lvl);
  const {values, handleChange, onSubmit, createLoading} = useCreatingUser(refetch);
  const onEditSuccess = () => {
    setIsEditing(false);
    setFocusedId('');
    refetch();
  };
  const {
    values: eValues,
    handleChange: eChange,
    onUpdate,
  } = useEditingUser(
    data.find((el) => el.id?.id === focusedId),
    focusedId,
    onEditSuccess,
  );
  const cancelEdit = () => setIsEditing(false);

  useEffect(() => {
    if (delModalLvl === ModalLvl.confirm) {
      Modal.confirm({
        title: `You are request to remove {userName}?`,
        content: (
          <ModalDescr>Upon confirming, the user will no longer have access to the Promo Code Portal.</ModalDescr>
        ),
        okText: 'Yes',
        cancelText: 'No',
        autoFocusButton: null,
        icon: <QuestionCircleOutlined />,
        onOk: () => setDelPopup(ModalLvl.success),
        onCancel: () => setDelPopup(ModalLvl.closed),
      });
    }
    if (delModalLvl === ModalLvl.success) {
      Modal.success({
        title: `{userName} has been removed!`,
        content: <ModalDescr>The user will no longer have access to the Promo Code Portal.</ModalDescr>,
        okText: 'Done',
        autoFocusButton: null,
        onOk: () => {
          onDelete?.(focusedId);
          console.log('delete', focusedId);
          setDelPopup(ModalLvl.closed);
        },
      });
    }
  }, [delModalLvl]);

  const columns: TableColumnT[] = [
    {
      key: ManagementTableFields.fullName,
      dataIndex: ManagementTableFields.fullName,
      title: ManagementTableColumnsTitles.fullName,
      render: (props: FullNameItemProps) => (
        <FullNameItem
          {...props}
          value={values.name}
          onChange={handleChange}
          isEditing={isEditing}
          focusedId={focusedId}
          editingValue={eValues.name}
          onEdit={eChange}
        />
      ),
    },
    {
      key: ManagementTableFields.email,
      dataIndex: ManagementTableFields.email,
      title: ManagementTableColumnsTitles.email,
      render: (props: EmailItemProps) => (
        <EmailItem
          {...props}
          value={values.email}
          onChange={handleChange}
          isEditing={isEditing}
          focusedId={focusedId}
          editingValue={eValues.email}
          onEdit={eChange}
        />
      ),
    },
    {
      key: ManagementTableFields.role,
      dataIndex: ManagementTableFields.role,
      title: ManagementTableColumnsTitles.role,
      render: (props: RoleItemProps) => (
        <RoleItem
          {...props}
          value={values.roleId}
          onChange={handleChange}
          options={roles}
          isEditing={isEditing}
          focusedId={focusedId}
          editingValue={eValues.roleId}
          onEdit={eChange}
        />
      ),
    },
    {
      key: ManagementTableFields.department,
      dataIndex: ManagementTableFields.department,
      title: ManagementTableColumnsTitles.department,
      render: (props: DepartmentItemProps) => (
        <DepartmentItem
          {...props}
          value={values.departmentId}
          onChange={handleChange}
          options={departments}
          isEditing={isEditing}
          focusedId={focusedId}
          editingValue={eValues.departmentId}
          onEdit={eChange}
        />
      ),
    },
    {
      key: ManagementTableFields.access,
      dataIndex: ManagementTableFields.access,
      title: ManagementTableColumnsTitles.access,
      render: (props: ActionsItemProps) => (
        <AccessItem {...props} isEditing={isEditing} focusedId={focusedId} onChange={handleChange} />
      ),
    },
    {
      key: ManagementTableFields.actions,
      dataIndex: ManagementTableFields.actions,
      title: ManagementTableColumnsTitles.actions,
      render: (props: ActionsItemProps) => (
        <ActionsItem
          {...props}
          setDelPopup={setDelPopup}
          isEditing={isEditing}
          setEditing={setEditing}
          setFocusId={setFocusId}
          focusedId={focusedId}
          onAdd={onSubmit}
          loading={createLoading}
          onEdit={onUpdate}
          onCancelEdit={cancelEdit}
        />
      ),
    },
  ];
  return (
    <>
      <TableWrapper>
        <UsersFilter departments={departments} roles={roles} filterUsers={filterUsers} />
        <Table columns={columns} dataSource={data} pagination={false} />
      </TableWrapper>
    </>
  );
};
