import React, {useState} from 'react';
import {
  ActionsButtons,
  ActionBtn,
  ItemWrapper,
  RoleSelect,
  DepartmentSelect,
  AccessSelect,
  FullNameInput,
  EmailInput,
  ClearButton,
  FilterSelect,
  UsersFilterWrapper,
} from '../styles';
import {ManageItemT} from '../../../types/settings';
import {getAccessOptions} from '../../../helpers/settings';
import {Button, ButtonTypes} from '../../../ui-kit/Button';
import {MenuMore} from '../../Dashboard/ui/desktop/Table';
import {useEditingUserT, useCreatingUserT} from '../../../hooks/settings';
import {SelectOptionT} from '../../../types/helpers';

export type CreateEditT = {
  value?: string;
  editingValue?: string;
  onEdit?: useEditingUserT['handleChange'];
  onChange?: useCreatingUserT['handleChange'];
  options?: SelectOptionT[];
};

export type FullNameItemProps = ManageItemT & CreateEditT;

export type UsersFilterProps = {
  departments?: SelectOptionT[];
  roles?: SelectOptionT[];
  filterUsers: (v: string[]) => void;
};

export const FullNameItem: React.FC<FullNameItemProps> = ({
  fullName,
  addRow,
  value,
  onChange,
  isEditing,
  id,
  focusedId,
  editingValue,
  onEdit,
}) => {
  const handleChange = (e?: any) => onChange?.({name: 'name', value: e?.target?.value});
  const handleEdit = (e?: any) => onEdit?.({name: 'name', value: e?.target?.value});
  const isCurrentEditing = focusedId === id && isEditing;
  return (
    <ItemWrapper>
      {isCurrentEditing ? (
        <FullNameInput placeholder="Type name" value={editingValue} onChange={handleEdit} />
      ) : addRow && !isCurrentEditing ? (
        <FullNameInput placeholder="Type name" value={value} onChange={handleChange} />
      ) : (
        <div>{fullName}</div>
      )}
    </ItemWrapper>
  );
};

export type EmailItemProps = ManageItemT & CreateEditT;

export const EmailItem: React.FC<EmailItemProps> = ({
  email,
  addRow,
  value,
  onChange,
  editingValue,
  isEditing,
  id,
  onEdit,
  focusedId,
}) => {
  const handleChange = (e?: any) => onChange?.({name: 'email', value: e?.target?.value});
  const handleEdit = (value?: any) => onEdit?.({name: 'email', value: value});
  const isCurrentEditing = focusedId === id && isEditing;

  return (
    <ItemWrapper>
      {isCurrentEditing ? (
        <EmailInput placeholder="Type email" onChange={handleEdit} value={editingValue} />
      ) : addRow ? (
        <EmailInput placeholder="Type email" onChange={handleChange} value={value} />
      ) : (
        <div>{email}</div>
      )}
    </ItemWrapper>
  );
};

export type RoleItemProps = ManageItemT & CreateEditT;

export const RoleItem: React.FC<RoleItemProps> = ({
  role,
  addRow,
  value,
  onChange,
  options,
  editingValue,
  id,
  onEdit,
  isEditing,
  focusedId,
}) => {
  const handleChange = (value?: any) => onChange?.({name: 'roleId', value: value});
  const handleEdit = (value?: any) => onEdit?.({name: 'roleId', value: value});
  const isCurrentEditing = focusedId === id && isEditing;
  return (
    <ItemWrapper>
      {isCurrentEditing ? (
        <RoleSelect options={options} placeholder="Choose Role" value={editingValue} onChange={handleEdit} />
      ) : addRow ? (
        <RoleSelect options={options} placeholder="Choose Role" value={value} onChange={handleChange} />
      ) : (
        <div>{role}</div>
      )}
    </ItemWrapper>
  );
};

export type DepartmentItemProps = ManageItemT & CreateEditT;

export const DepartmentItem: React.FC<DepartmentItemProps> = ({
  department,
  addRow,
  value,
  onChange,
  options,
  editingValue,
  onEdit,
  id,
  isEditing,
  focusedId,
}) => {
  const handleChange = (value?: any) => onChange?.({name: 'departmentId', value: value});
  const handleEdit = (value?: any) => onEdit?.({name: 'departmentId', value: value});
  const isCurrentEditing = focusedId === id && isEditing;

  return (
    <ItemWrapper>
      {isCurrentEditing ? (
        <DepartmentSelect options={options} placeholder="Choose Depart..." value={editingValue} onChange={handleEdit} />
      ) : addRow ? (
        <DepartmentSelect options={options} placeholder="Choose Depart..." value={value} onChange={handleChange} />
      ) : (
        <div>{department}</div>
      )}
    </ItemWrapper>
  );
};

export type AccessItemProps = ManageItemT & CreateEditT;

export const AccessItem: React.FC<AccessItemProps> = ({access, addRow, onChange, isEditing, id, focusedId}) => {
  const options = getAccessOptions();
  const handleChange = (value?: any) => onChange?.({name: 'access', value: value});
  const isCurrentEditing = focusedId === id && isEditing;

  return (
    <ItemWrapper>
      {addRow || isCurrentEditing ? (
        <AccessSelect options={options} onChange={handleChange} initial={access} placeholder="Select portals" />
      ) : (
        <div>
          {access?.map((el) => (
            <>
              {el}
              <br />
            </>
          ))}
        </div>
      )}
    </ItemWrapper>
  );
};

export type ActionsItemProps = ManageItemT & {
  onAdd?: () => void;
  loading?: boolean;
  onEdit?: () => void;
  onCancelEdit?: () => void;
};

export const ActionsItem: React.FC<ActionsItemProps> = ({
  addRow,
  onAdd,
  isEditing,
  loading,
  id,
  focusedId,
  onCancelEdit,
  onEdit,
  ...props
}) => {
  const isCurrentEditing = focusedId === id && isEditing;
  return (
    <ActionsButtons>
      {isCurrentEditing ? (
        <>
          <Button type={'text'} onClick={onCancelEdit}>
            Cancel
          </Button>
          <Button onClick={onEdit}>Update</Button>
        </>
      ) : addRow ? (
        <ActionBtn variant={ButtonTypes.secondary} onClick={onAdd} disabled={loading}>
          Add
        </ActionBtn>
      ) : (
        // eslint-disable-next-line
        // @ts-ignore
        <MenuMore {...props} isEditing={isEditing} id={id} />
      )}
    </ActionsButtons>
  );
};

export const UsersFilter: React.FC<UsersFilterProps> = ({departments, roles, filterUsers}) => {
  const [value, setValue] = useState<string[] | never>([]);
  const accessOptions = getAccessOptions();
  const onFilter = (v: string[]) => {
    setValue([...v]);
    filterUsers(v);
  };
  const onClearClick = () => {
    filterUsers([]);
    setValue([]);
  };

  return (
    <UsersFilterWrapper>
      <FilterSelect
        // eslint-disable-next-line
        //@ts-ignore
        onChange={onFilter}
        value={value}
        mode="multiple"
        placeholder="Select filters"
        options={[
          {
            label: 'Department',
            options: departments?.map((dep) => ({label: dep.label, value: dep.value, id: dep.value})),
          },
          {
            label: 'Role',
            options: roles?.map((role) => ({label: role.label, value: role.value, id: role.value})),
          },
          {
            label: 'Portal Access',
            options: accessOptions?.map((option) => ({label: option.label, value: option.value, id: option.value})),
          },
        ]}
      />
      {value.length >= 1 && <ClearButton onClick={onClearClick}>Clear Filter</ClearButton>}
    </UsersFilterWrapper>
  );
};
