import {DashboardTableDataT, PaginationObjT} from '../../../types/dashboard';
import {MessageTypes} from '../../../types/messages';
import {ButtonTypes} from '../../../ui-kit/Button';

export type DashboardPageProps = {
  notifications?: DashboardTableDataT[];
  goEdit: (id: string) => void;
  onDelete: (id: string) => Promise<boolean>;
  loading?: boolean;
  searchNotifications: (v: string) => void;
  isSearching?: boolean;
  pagination: PaginationObjT;
};

export const DashboardCodeMap = {
  [MessageTypes.scheduled]: ButtonTypes.quinary,
  [MessageTypes.sent]: ButtonTypes.secondary,
  [MessageTypes.instant]: ButtonTypes.secondary,
};
